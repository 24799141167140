
import React from 'react';
import { withRouter } from 'react-router'

import { withStyles } from '@material-ui/core/styles';
import withRoot from './../../withRoot';
import * as actions from './../../redux/actions'
import { connect } from "react-redux";

import TehnofanElement from './tehnofan/TehnofanElement'


const  CustomElement = (props)=> {
    const { elementId, handleClick } = props


    if(elementId.split("_").includes("tehnofan")){
      return <TehnofanElement {...props} />
    }else return null


}




export default CustomElement
