import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles';

import classNames from 'classnames'

import Grid from '@material-ui/core/Grid'

const styles = theme => ({

  galleryItem:{
    overflow:"hidden",
    height: "30vh",

    position: "relative",
    boxShadow: theme.shadows[1],
    cursor: "pointer",
    "&:hover":{
      boxShadow: theme.shadows[8],
    }
  },
  galleryItemLarge:{
    overflow:"hidden",
    height: "250px",
    width: "100%",
    position: "relative",
    boxShadow: theme.shadows[1],
    cursor: "pointer",
    "&:hover":{
      boxShadow: theme.shadows[8],
    }
  },
  imageOverlay:{
    position:"absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",

  },
  galleryItemInfo:{
    position:"absolute",
    top: 0,
    right: 0,
    width: "100%",
    background: theme.palette.background.main,
    height: "20%",
    boxShadow: theme.shadows[1],
    borderBottom: "2px solid "+ theme.palette.secondary.main,
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center",
    transition: "all .35s ease-in-out"
  },
  galleryItemInfoLarge:{
    height: "100%",
    transition: "all .35s ease-in-out",

    background: theme.palette.background.transparent,
  },
  imageTitle: {
    position:"absolute",
    fontSize: 20,
    fontWeight: "bold",
    textTransform: "uppercase"
  },
  imageTitleActive:{

    borderBottom: "2px solid "+ theme.palette.secondary.main,
  },

  /// SMALL

  galleryItemSmall:{
    overflow:"hidden",
    height: 250,

    position: "relative",
    boxShadow: theme.shadows[1],
    cursor: "pointer",
    "&:hover":{
      boxShadow: theme.shadows[8],
    }
  },
  galleryItemInfoSmall:{
    position:"absolute",
    bottom: 0,
    right: 0,
    width: "100%",
    background: theme.palette.background.main,
    height: "25%",
    boxShadow: theme.shadows[10],

    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center",
    transition: "all .35s ease-in-out"
  },
  imageTitleSmall:{
    position:"absolute",
    fontSize: 17,
    fontWeight: "bold",
  }

});

class ImageButton extends React.Component {
  state={
    active: false,
  }

  activate = ()=>{
    this.setState({active: true})
  }

  deactivate = ()=>{
    this.setState({active: false})
  }
  render(){
    const {classes, fileUrl, title, xs, sm, md, lg } = this.props
    return(
      <Grid item xs={xs} sm={sm} md={md} lg={lg}>
        <div
          onClick={this.props.onClick}
          style={{background: `url(${fileUrl})`,backgroundRepeat: "no-repeat", backgroundSize: "cover",}}
          className={ classes.galleryItemLarge}
          onMouseEnter={this.activate}
          onMouseLeave={this.deactivate}
        >
          <span className={classes.imageOverlay}></span>
          <div className={this.state.active ? classNames(classes.galleryItemInfo, classes.galleryItemInfoLarge): classes.galleryItemInfo}>
            <Typography variant="h4" className={this.state.active ? classNames(classes.imageTitle, classes.imageTitleActive):classes.imageTitle} >{title}</Typography>
          </div>
        </div>
      </Grid>

    )
  }

}

ImageButton.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ImageButton)
