
import { SET_ACTIVE_DOCUMENT_PAGE } from "./../../actions/types";

/// its set value of current scroll position
// if(scrollPositon < 100vh) state = 0
// variable needed to dynamically load content on pages larger than 100vh
export default (state = 0, action) => {
  switch (action.type) {
    case SET_ACTIVE_DOCUMENT_PAGE:
      return action.payload;
    default:
      return state;
  }
};
