import React from 'react';
import { withRouter } from 'react-router'
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import withRoot from './../withRoot';
import * as actions from './../redux/actions'
import { connect } from "react-redux";
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import queryString from 'query-string'

import Preloader from './../webElements/default/preloaders/Preloader'

const styles = theme => ({

  root: {

    position:"relative",
    textAlign: 'center',
    height: "100vh"
  },

  paper:{
    padding: theme.spacing.unit * 3,
  }

});


class LoginPage extends React.Component {
  state = {
    redirect: "/"
  }
  componentDidMount(){
    if(this.props.location.search){
      let query = queryString.parse(this.props.location.search)
      this.setState({redirect: query.redirect})

      if(this.props.auth)this.props.history.push(query.redirect)
    }else{
      if(this.props.auth)this.props.history.push("/")
    }
  }

  componentDidUpdate(prevProps){
    if(prevProps.auth !== this.props.auth ){
      if(this.props.auth)this.props.history.push(this.state.redirect)
    }
  }

  render() {
    const { classes, auth } = this.props;
    if(auth !== "loading"){
      return (
          <div className={classes.root}>
            {
              /*
              <Formik
                initialValues={{ username: '', password: '' }}
                onSubmit={async (values, actions) => {

                  let status = await this.props.authenticateUser(values.username, values.password)
                  if(status.error){

                    actions.setFieldError("username","Wrong username!")
                    actions.setFieldError("password","Wrong password!")
                    actions.setFieldValue("username", "")
                    actions.setFieldValue("password", "")
                    actions.setSubmitting(false)

                  }
                }}
                validationSchema={Yup.object().shape({
                  username: Yup.string().email()
                    .required('Required'),
                  password: Yup.string()
                    .required('Required'),
                })}
              >
                {props => {
                  const {
                    values,
                    touched,
                    errors,
                    dirty,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    handleReset,
                  } = props;
                  return (
                    <form onSubmit={handleSubmit}>
                      <Grid container direction="column" justify="center" alignItems="center" style={{height: "100vh"}}>
                        <Grid item sm={3}>
                          <Paper className={classes.paper}>
                            <Grid container spacing={3}>
                              <Grid item sm={12}>
                                <FormControl error={errors.username && touched.username} fullWidth>
                                  <InputLabel htmlFor="component-error">E-mail</InputLabel>
                                  <Input
                                    id="username"

                                    value={values.username}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    aria-describedby="component-error-text"
                                  />
                                  {errors.username &&
                                    touched.username && <FormHelperText id="component-error-text">{errors.username}</FormHelperText>}
                                </FormControl>
                              </Grid>
                              <Grid item sm={12}>
                                <FormControl error={errors.password && touched.password} fullWidth>
                                  <InputLabel htmlFor="component-error">Password</InputLabel>
                                  <Input
                                    id="password"
                                    type="password"
                                    value={values.password}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    aria-describedby="component-error-text"
                                  />
                                  {errors.password &&
                                    touched.password && <FormHelperText id="component-error-text">{errors.password}</FormHelperText>}
                                </FormControl>
                              </Grid>
                              <Grid item sm={12} style={{display:"flex", justifyContent: "flex-end"}}>
                                <Button variant="contained" color="primary" type="submit" disabled={isSubmitting}>
                                  Login
                                </Button>
                              </Grid>
                              <Grid item sm={12} style={{display:"flex", justifyContent: "flex-end"}}>

                              </Grid>
                            </Grid>
                          </Paper>
                        </Grid>
                      </Grid>
                    </form>
                  );
                }}
              </Formik>
              */
            }
            <Grid container direction="column" justify="center" alignItems="center" style={{height: "100vh"}}>
              <Grid item sm={3}>
                <Paper className={classes.paper}>
                  <Button onClick={()=>this.props.authenticateUserWithGoogle("admin")} fullWidth variant="contained" color="primary" >
                    Login with Google
                  </Button>
                </Paper>
              </Grid>
            </Grid>
          </div>
      );
    }else return <Preloader />


  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = ({ auth }) => {
  return {
    auth
  }
}

export default withRouter(withRoot(withStyles(styles)(connect(mapStateToProps, actions)(LoginPage))))
//<img alt="tears" src={WATER_DROPS} className={classes.backgroundImage} />
