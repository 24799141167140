
import {
  FETCH_PAGE,
  FETCH_ALL_PAGES,
  ADD_NEW_PAGE,
  DELETE_PAGE,
  UPDATE_PAGE,

} from "./../../../actions/types";


export default (state = "loading", action) => {
  switch (action.type) {
    case FETCH_PAGE:
      return state !== "loading" ? { ...state, ...action.payload} : action.payload;
    case FETCH_ALL_PAGES:
      return action.payload;
    case ADD_NEW_PAGE:
      if(state !== "loading") state = {...state, ...action.payload}
      else state = action.payload
      return {...state}
    case UPDATE_PAGE:
      state[action.pageId].elements = {...state[action.pageId].elements, ...action.payload}

      return {...state}
    case DELETE_PAGE:
      if(state[action.pageId]) delete state[action.pageId]
      return {...state}

    default:
      return state;
  }
};
