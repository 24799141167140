import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';

import { withStyles } from '@material-ui/core/styles';

import LeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import RightIcon from '@material-ui/icons/KeyboardArrowRight';

import classNames from 'classnames'

import Element from './../../../Element'

const ROOT_HEIGHT = 500

const CAROUSEL_PADDING = 0

const styles = theme => ({
  root: {

    position: 'relative',

    width: "100%",
    height: "100%",
    overflow: "hidden"
  },
  absolute:{
    position:"absolute",
    top: 0,
    width: "100%",
    height: "100%"
  },
  column:{
    position: "relative",
    height:350,

    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center",
  },
  shadow:{
    position: "relative",
    zIndex: 20,
    boxShadow: theme.shadows[3]
  },
  sliderContainer:{
    height: "100%",
    position:"absolute",
    left: 0,
    top: 0,
    display:"flex",
    flexDirection:"row",

  },
  elementWrapper:{
    width:"100%",
    height:"100%",
    position:"relative",
  },
  element:{
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,


  },

  firstPageElement:{
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,

  },
  buttonLeftWrapper:{
    position: "absolute",
    top: 0,
    bottom: 0,
    left:0,

    display: "flex",
    flexDirection:"column",
    justifyContent:"center"
  },
  buttonLeft:{
    borderRadius: 0,
    background: "rgba(255,255,255,0.8)",
    zIndex:502,
  },
  buttonRightWrapper:{
    position: "absolute",
    top: 0,
    bottom: 0,
    right:0,

    display: "flex",
    flexDirection:"column",
    justifyContent:"center"
  },
  buttonRight:{
    borderRadius: 0,
    background: "rgba(255,255,255,0.8)",
    zIndex:502,
  },
  icon:{
    fontSize: 60,
    color: theme.palette.primary.main
  },
  overlay:{
    position: "absolute",
    top: 0,
    left: 0,

    width: "100%",
    height: "100%",
    background: "rgba(255,255,255,0.65)",
    zIndex: 500,
  },



  galleryItemInfo:{
    position:"absolute",
    bottom: 0,
    right: 0,
    width: "100%",
    background: theme.palette.background.transparent,
    height: "20%",
    boxShadow: theme.shadows[5],

    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center",
    transition: "all .35s ease-in-out"
  },
  galleryItemInfoLarge:{
    height: "100%",
    transition: "all .35s ease-in-out",

    background: theme.palette.background.transparent,
  },
  imageTitle: {

    fontSize: 20,
    fontWeight: 600,
    textTransform: "uppercase",
    borderBottom: "2px solid rgba(0,0,0,0)",


  },
  imageTitleActive:{
    fontSize: 32,
    fontWeight: 600,
    textTransform: "uppercase",
    borderBottom: "2px solid "+ theme.palette.secondary.main,

    [theme.breakpoints.down("lg")]:{
      fontSize: 20,
    }
  },
  imageElementButtonWrapper:{
    position: "absolute",
    left: 0,
    right: 0,
    bottom: theme.spacing.unit * 4,

  }


});




class Carousel extends React.Component {
  state = {
    activeElement: 0,
    transition: true,
    width: 0,
    height: 0,
    slideDirection : "right",
    intervalId: null
  };

  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)

    if(this.props.children.length > 1)this.startSlideShow()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
    clearInterval(this.state.intervalId);
  }

  startSlideShow = ()=>{

    let intervalId  = setInterval(()=>{

      this.setState({activeElement: (this.state.activeElement+1) % this.props.children.length})
    }, 10000);

    this.setState({intervalId})
  }

  updateWindowDimensions = () => {
    this.setState({ width: ((window.innerWidth)/12) * this.props.containerWidth - CAROUSEL_PADDING, height: window.innerHeight })
  }

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleClick = () => {
    this.setState({
      open: true,
    });
  };

  handleLeftClick = ()=>{

    this.setState({activeElement: this.state.activeElement-1, slideDirection: "left"})
  }

  handleRightClick = ()=>{

    this.setState({activeElement: this.state.activeElement + 1, slideDirection: "right"})
  }

  render() {
    const { classes, children, disableButtons, backgroundImage, childrenId } = this.props;
    const { activeElement, width} = this.state;

    let numberOfElements = children && children.length
    let position = -activeElement*width

    let style={
      left: position,
      transition: "left .35s ease-in-out"
    }


    //{this.firstPage()}
    return (

      <div className={classes.root}>
          {!disableButtons && activeElement !== 0 &&
            <div className={classes.buttonLeftWrapper}>
                <IconButton disabled={activeElement === 0} className={classes.buttonLeft} onClick={this.handleLeftClick}>
                   <LeftIcon className={classes.icon} />
                </IconButton>
            </div>
          }
          <div style={{width: `calc(${ width * children.length}px)`, left: position}} className={classes.sliderContainer}>
            {backgroundImage &&
              <div className={classes.absolute} style={{opacity: 0.2}}>
                <img alt="background" src={backgroundImage} width="100%" height="100%"/>
              </div>
            }

            { children && children.map((child, i)=>{
              return(
                <div key={childrenId} style={{height:"100%"}} className={classes.elementWrapper}>
                  {child}
                </div>
              )

            })}
          </div>
          {!disableButtons && activeElement !== numberOfElements-1 &&
            <div className={classes.buttonRightWrapper}>

                <IconButton disabled={activeElement === numberOfElements-1}  className={classes.buttonRight} onClick={this.handleRightClick}>
                   <RightIcon className={classes.icon} />
                </IconButton>

            </div>
          }
      </div>
    );
  }
}

Carousel.propTypes = {

  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Carousel);
