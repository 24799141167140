import React from 'react';

import PropTypes from 'prop-types';

import Grow from '@material-ui/core/Grow';
import Slide from '@material-ui/core/Slide';
import Collapse from '@material-ui/core/Collapse';
import Fade from '@material-ui/core/Fade';

function animationWrapper(WrappedComponent){

  function AnimationWrapper(props){
    const {animationType, animationDirection, animationActive, animationDelay, animationDuration, elementId} = props

    const [animate, setAnimate] = React.useState(animationActive)

    React.useEffect(()=>{
      if(animationActive && animationDelay){
        setTimeout(()=> setAnimate(animationActive), Number(animationDelay))
      }else{
        setAnimate(animationActive)
      }

    },[animationActive])


    switch (animationType) {
      case "grow":
        return (
          <Grow  in={animate} timeout={Number(animationDuration)} >
            <WrappedComponent {...props}/>
          </Grow>
        )
      case "fade":
        return (
          <Fade  in={animate} timeout={Number(animationDuration)} >
            <WrappedComponent {...props}/>
          </Fade>
        )
      case "slide":
        return (
          <Slide  in={animate} timeout={Number(animationDuration)} direction={animationDirection} >
            <WrappedComponent {...props}/>
          </Slide>
        )
      case "collapse":
        return (
          <Collapse   in={animate} timeout={Number(animationDuration)} >
            <WrappedComponent {...props}/>
          </Collapse>
        )
      default:
        return <WrappedComponent {...props}/>

    }

  }

  AnimationWrapper.defaultProps = {
    animationActive: false,
    animationDuration: 1000,
    animationDirection: "left",
    animationDelay: 1000
  };

  return AnimationWrapper
}

export default animationWrapper
