import React from 'react';

import { connect } from "react-redux";
import { withRouter } from 'react-router'
import * as actions from '../../../redux/actions'
import PropTypes from 'prop-types';

import { Formik, Field } from 'formik';
import * as Yup from 'yup';


import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import SendIcon from '@material-ui/icons/Send';

import editWrapper from '../wrappers/editWrapper'
import CustomInputComponent from '../../components/CustomInputComponent'

import CircularProgress from '@material-ui/core/CircularProgress';

import * as defaultCss from '../../defaultCss'

import parseStringToHtml from '../../../helpers/parseStringToHtml'


const styles = theme => ({
  ...defaultCss.styles(theme),
  root:{
    minHeight: "calc(100vh - 200px)",
    padding: theme.spacing(3)
  }
});


function SearchedHitsElement(props) {
  const { location, classes, history } = props
  const [searchedHits, setSearchedHits] = React.useState(null)
  
  const searchedString = location.search ? 
                          location.search.string ? 
                            location.search.string : "" 
                        : ""

  React.useEffect(()=>{
    if(searchedString){
      fetchSearchedResults(location.search)
    } 
  }, [])

  const fetchSearchedResults = async (searchedString)=>{
      setSearchedHits("loading")
      let searchedHits = await props.fetchSearchedResults(searchedString)
    
      setSearchedHits(searchedHits)
  }
  const goToPage = pageId => () => {
    history.push(pageId.replace(/_/g, "/"))
  }

  return (
    <Grid container className={classes.root} justify="center">
      <Grid item xs={12} sm={12} md={11} lg={10}>
        <Box mb={3} className={classes.homePageTitle}>
          Iskalnik vsebine
        </Box>
        <Formik
          initialValues={{ search: searchedString}}
          onSubmit={async (values, actions) => {
            fetchSearchedResults(values.search)
          }}
          validationSchema={Yup.object().shape()}
        >
          {props => {
            const {
              handleSubmit,
            } = props;

            return (
              <Box display="flex" >
                <Field
                  name="search"
                  label="Išci"
                  component={CustomInputComponent}
                />
                <Button onClick={handleSubmit} variant="contained" color="primary">
                  <SendIcon />
                </Button>
              </Box>
            );
          }}
        </Formik>
        <Box display="flex" flexDirection="column" >
          {searchedHits === "loading" && 
            <Box pt={3}>
              <CircularProgress />
            </Box>
          }

          {searchedHits && searchedHits !== "loading" && 
            <Box>
              {
                Object.entries(searchedHits).map(([pageId, pageHit]) => (
                  <Box key={pageId} display="flex" flexDirection="column" mt={3} bgcolor="background.main" boxShadow={3} onClick={goToPage(pageId)} style={{cursor: "pointer"}}>
                    <Box fontSize={26} fontWeight={200} color="white"  textAlign="left" p={2} bgcolor="primary.main">
                      {pageHit.name}
                    </Box>
                    <Box fontSize={16} fontWeight={300} textAlign="justify" height={75} textOverflow="ellipsis" overflow="hidden" p={2}>
                      {pageHit.content ? parseStringToHtml(pageHit.content) : parseStringToHtml(pageHit.title)}
                    </Box>
                  </Box>
                ))
              }
            </Box>
          }
        </Box>
      </Grid>
    </Grid>
  )
  

  
}

SearchedHitsElement.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = ({ activeDocumentPage }) => {
  return {
    activeDocumentPage
  }
}

export default withRouter(connect(mapStateToProps, actions)(editWrapper(withStyles(styles)(SearchedHitsElement))))
