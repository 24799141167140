import {

  SET_ACTIVE_MODAL,
  CLOSE_ACTIVE_MODAL,
  SET_ACTIVE_DRAWER,
  SET_PREVIOUS_MODAL,
  SET_SELECTED_ELEMENT,

} from './../types'

export const setActiveModal  = (modalId) => async dispatch =>{

  dispatch({
    type: SET_ACTIVE_MODAL,
    payload: modalId
  })
}

export const closeActiveModal  = () => async dispatch =>{

  dispatch({
    type: CLOSE_ACTIVE_MODAL,
    payload: []
  })
}

export const setPreviousActiveModal  = () => async dispatch =>{

  dispatch({
    type: SET_PREVIOUS_MODAL,
  })
}

export const setSelectedElement  = (elementId) => async dispatch =>{

    dispatch({
      type: SET_SELECTED_ELEMENT,
      payload: elementId
    })

}

export const setActiveDrawer = (drawerId) => dispatch =>{
  dispatch({
    type: SET_ACTIVE_DRAWER,
    payload: drawerId
  })

}
