import React from 'react';
import { withRouter } from 'react-router'
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import classNames from 'classnames'

import * as defaultCss  from './../../defaultCss'
import editWrapper from './../wrappers/editWrapper'

const styles = theme => ({
  ...defaultCss.styles(theme),
  root:{
    position: "relative",

  },

});


class GridWrapper extends React.Component {

  state = {

  }

  render() {
    const { classes, handleClick, justify, direction, alignItems, style, spacing} = this.props


    return (
      <Grid
        container
        onClick={handleClick}
        className={classNames(classes.root, classes[this.props.classNames])}
        spacing={spacing ? Number(spacing) : 0}
        justify={justify ? justify : null}
        direction={direction ? direction : null}
        alignItems={alignItems ? alignItems : null}
        style={style ? style.reduce((obj, tmp)=> {return {...obj, [tmp.name]: tmp.value}},{}):{}}
        wrap="wrap"
      >
        {this.props.children}

      </Grid>
    )
  }
}

GridWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(editWrapper(withStyles(styles)(GridWrapper)))
