import React from 'react';
import { withRouter } from 'react-router'
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';


import Button from '@material-ui/core/Button';

import * as defaultCss  from './../../defaultCss'

import Typography from '@material-ui/core/Typography';

import parseStringToHtml from './../../../helpers/parseStringToHtml'
import translateElement from './../wrappers/translateElement'
import editWrapper from './../wrappers/editWrapper'
import animationWrapper from './../wrappers/animationWrapper'

const styles = theme => ({
  ...defaultCss.styles(theme),
  active:{
    border: "2px solid red",
  }
});

class ButtonWrapper extends React.Component {

  state = {

  }

  goTo = ()=>{

    if(this.props.buttonType === "Path"){
      if(this.props.path){
        let path =  this.props.path.replace(/_/g, "/")
        this.props.history.push(path)
      }
    }else if(this.props.buttonType === "Redirect"){
      window.location.href = this.props.redirect
    }
  }

  render() {
    const { classes, handleClick, selectedElement, style, variant, label, align, edit, color} = this.props


    return (
      <Button
        onClick={edit ? handleClick: this.goTo}
        className={classes[this.props.classNames]}
        variant={variant}
        color={color}
        style={style && !Array.isArray(style) && style}
      >
        <Typography
          align={align}
          style={style  && Array.isArray(style) ? {...style.reduce((obj, tmp)=> {return {...obj, [tmp.name]: tmp.value}},{}), background: "none"} : {background: "none"}}
          className={classes[this.props.classNames]}

        >
          {parseStringToHtml(label, {b:{color:"white"}})}
        </Typography>
      </Button>
    )
  }
}

ButtonWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
};


export default withRouter(animationWrapper(translateElement(editWrapper(withStyles(styles)(ButtonWrapper)))))
