import React from 'react';
import { withRouter } from 'react-router'
import PropTypes from 'prop-types';
import Fade from '@material-ui/core/Fade';
import Box from '@material-ui/core/Box';
import Fab from '@material-ui/core/Fab';
import BuildIcon from '@material-ui/icons/Build';
import WidgetIcon from '@material-ui/icons/Widgets';
import { withStyles } from '@material-ui/core/styles';
import withRoot from './../withRoot';
import * as actions from './../redux/actions'
import { connect } from "react-redux";
import Preloader from './default/preloaders/Preloader'
import Element from './Element'
import { Helmet } from "react-helmet";

const styles = theme => ({

  root: {
    position:"relative",

    minHeight: "100vh",

  },

  adminButtons:{

    bottom: "24px",
    right: "24px",
    [theme.breakpoints.down('sm')]: {
      bottom: "16px",
      left: "16px",
      right: "initial"
    },
  }

});


class PageView extends React.Component {
  state = {
    pageId: "_home",
  }

  async componentDidMount(){
    window.addEventListener('scroll', this.listenToScroll)

    let pageId = this.props.location.pathname.replace(/\//g, "_")
    pageId = pageId === "_" ? "_home" : pageId

    this.setState({pageId})
    this.props.setSelectedPage(pageId)

    if(this.props.webpages === "loading"){
      
      if(! await this.props.fetchWebpageById(pageId)){
        
        this.setState({pageId: "_home"})
        this.props.setSelectedPage("_home")
        this.props.fetchWebpageById("_home")
      } 
    } 

  }

  componentWillUnmount() {
    window.removeEventListener('scroll',  this.listenToScroll)
  }



  componentDidUpdate(prevProps){
    if(prevProps.location !== this.props.location){

      let pageId = this.props.location.pathname.replace(/\//g, "_")
      pageId = pageId === "_" ? "_home" : pageId
      
      this.props.fetchWebpageById(pageId)
      this.setState({pageId})
      this.props.setSelectedPage(pageId)

    }

  }


  listenToScroll = (e) => {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop

    const windowHeight = window.innerHeight
            || document.documentElement.clientHeight
            || document.body.clientHeight;

    const activeDocumentPage = Math.ceil(winScroll/(windowHeight-200))

    if(activeDocumentPage > this.props.activeDocumentPage) {

      this.props.setActiveDocumentPage(activeDocumentPage)
    }

  }

  render() {
    const { classes, webpages, auth, globalElementsLoaded } = this.props;
    const { pageId } = this.state
    
    if(webpages !== "loading"  && globalElementsLoaded){
      let page = webpages[pageId]
      console.log(page)
      return (
        <Fade in={true} timeout={1000}>


          <div className={classes.root}>
              {
                page &&
                  <Helmet>
                    <title>{page.title ? page.title : page.name}</title>
                    <meta name="description" content={page.description} />
                  </Helmet>
              }
              {
                auth && auth.userInfo && auth.userInfo.admin && (
                  <Box position="fixed" className={classes.adminButtons} color="primary.main" zIndex="3000">
                    <Fab color="primary" style={{marginRight: 24}} onClick={()=>this.props.history.push("/controlpanel")}><WidgetIcon /></Fab>
                    <Fab color="secondary" style={{color: "white"}} onClick={()=>this.props.history.push(`/controlpanel/pagebuilder?page=${this.state.pageId}`)}><BuildIcon /></Fab>
                  </Box>
                )
              }

              {
                page ?
                  page.elements["root"] && page.elements["root"].children.map((elementId,i)=> <Element edit={false} selectedPage={pageId}  key={elementId} elementId={elementId} {...page.elements[elementId]}/>): 
                  <Preloader />
              }
          </div>
        </Fade>
      );
    }else return <Preloader />

  }
}

PageView.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = ({ webpages, auth, activeDocumentPage, globalElementsLoaded}) => {
  return {
    webpages,
    globalElementsLoaded,
    auth,
    activeDocumentPage,
  }
}

export default withRouter(connect(mapStateToProps, actions)(withRoot(withStyles(styles)(PageView))))
//<img alt="tears" src={WATER_DROPS} className={classes.backgroundImage} />
