import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import SubscriptionInput from './../../../components/SubscriptionInput'

import * as defaultCss from './../../../defaultCss'



const styles = theme => ({

  ...defaultCss.styles(theme),
  root: {
    position: "relative",

  },


});

function Subscription(props) {
  const { classes, style} = props;

  return (
    <div style={{width: "100%"}}  className={classes.root}>
        <Grid container justify="center" style={{width: "100%"}}>
          <Grid item sm={12} md={6} lg={2}>
              <SubscriptionInput />
          </Grid>
        </Grid>

    </div>
  );
}

Subscription.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Subscription)
