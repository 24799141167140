import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import withRoot from './../../../../withRoot';

import SvgIcon from '@material-ui/core/SvgIcon'
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import CallIcon from '@material-ui/icons/Call';
import MailIcon from '@material-ui/icons/MailOutline';

import externalIcons from './../../../../static/icons/externalIcons'
import editWrapper from './../../../default/wrappers/editWrapper'

const INFO_BAR_HEIGHT = 70

const styles = theme => ({
  root: {
    position:"relative",
    zIndex: 100,
    background: "white",
    height: 70,

    colro: theme.palette.primary.main,
  },
  column:{
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%"
  },
  row:{
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "100%"

  },

  fixedInfoBar:{
    position: "fixed",
    bottom: 0,
    width: "100%",

    borderTop: "1px solid "+ theme.palette.primary.main,

    height: INFO_BAR_HEIGHT,
    zIndex: 4000,
    background: theme.palette.background.secondary,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  infoBar:{

    width: "100%",

    borderTop: "1px solid "+ theme.palette.primary.main,

    height: INFO_BAR_HEIGHT,
    zIndex: 4000,
    background: theme.palette.background.secondary,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  container:{
    background: "white",
    padding: theme.spacing(3),

  },
  divider:{
    width: "100%",
    marginTop: 24,
    marginBottom: 24,
    background: theme.palette.secondary.main,
    height: 2,
  },

  button:{

    padding: 16,
    marginBottom: 24,


    minWidth: 300,
    borderRadius: 0,

    fontSize: 20,

  },
  footerImages:{
    height: 70,
    width: "auto",
    padding: theme.spacing(1),
  },
  textBar:{
    color:"white",
    fontSize: 18,
    fontWeight: 300
  },

  titleBar:{
    color:"white",
    fontSize: 14,
    fontWeight: 500
  },
  text:{

    fontSize: 15,
    fontWeight: 300
  },

  title:{
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    fontSize: 16,
    fontWeight: 500,

  },

  socialIcon:{
    height: 30,
    marginRight: 16,
    cursor: "pointer"
  }

});

class Footer extends React.Component {

  state = {
    footerIsVisible: false
  }

  componentDidMount(){

    this.props.fixedFooterInfoBar && window.addEventListener('scroll', this.listenToScroll)
  }

  componentWillUnmount() {
    this.props.fixedFooterInfoBar && window.removeEventListener('scroll',  this.listenToScroll)
  }


  listenToScroll = (e) => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop

    const bodyHeight =  document.body.clientHeight
    const windowHeight = window.innerHeight

    /// 430px ---> 500px is footer size, 70px is size of fixed info bar
    if(winScroll > (bodyHeight - windowHeight - 280)){
      if(!this.state.footerIsVisible)this.setState({footerIsVisible: true})
    }
    else if(this.state.footerIsVisible)this.setState({footerIsVisible: false})
  }



  render() {
    const { classes, fixedFooterInfoBar } = this.props;

    const socialIcons = (
      <div className={classes.row}>
        <img onClick={()=> window.location.href = "https://www.facebook.com/Tehnofan/"} alt="fb" className={classes.socialIcon} src={externalIcons.facebookIcon} />
        <img onClick={()=> window.location.href = "https://www.facebook.com/Tehnofan/"} alt="ig" className={classes.socialIcon} src={externalIcons.instagramIcon} />
        <img onClick={()=> window.location.href = "https://www.facebook.com/Tehnofan/"} alt="in" className={classes.socialIcon} src={externalIcons.linkedInIcon} />
        <img onClick={()=> window.location.href = "https://www.facebook.com/Tehnofan/"} alt="yt" className={classes.socialIcon} src={externalIcons.youtubeIcon} />
      </div>
    )

    return (
      <div className={classes.root}>

          <Grid
              container
              justify="space-between"
              style={{position: this.state.footerIsVisible ? "relative" : fixedFooterInfoBar ? "fixed"  : "relative"}}
              className={fixedFooterInfoBar ? classes.fixedInfoBar:classes.infoBar}>
              <Hidden xsDown>
                <Grid item lg={3}>
                  <div className={classes.row} style={{height: "100%"}}>
                    <div className={classes.column}>
                      <CallIcon style={{fontSize: 40, color:"white",}}/>
                    </div>
                    <div className={classes.column} style={{alignItems: "flex-start", paddingLeft: 16}}>
                      <Typography className={classes.titleBar}>Pokliči nas</Typography>
                      <Typography className={classes.textBar}>041 687 090 </Typography>
                    </div>
                  </div>
                </Grid>
                <Grid item lg={3}>
                  <div className={classes.row} style={{height: "100%"}}>
                    <div className={classes.column}>
                      <MailIcon style={{fontSize: 40, color:"white",}}/>
                    </div>
                    <div className={classes.column} style={{alignItems: "flex-start", paddingLeft: 16}}>
                      <Typography className={classes.titleBar}>Vprašaj nas</Typography>
                      <Typography className={classes.textBar}>info@tehnofan.si</Typography>
                    </div>
                  </div>
                </Grid>
                <Grid item lg={3}>
                  <div className={classes.row} style={{height: "100%"}}>

                    <div className={classes.column} style={{alignItems: "flex-start", paddingLeft: 16}}>
                      {/*<Typography className={classes.title}>Sledi nam</Typography>*/}
                      {socialIcons}
                    </div>
                  </div>
                </Grid>

              </Hidden>
          </Grid>


      </div>
    );
  }
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};



export default withRoot(withStyles(styles)(editWrapper(Footer)));
//<img alt="tears" src={WATER_DROPS} className={classes.backgroundImage} />
