import React from 'react';
import {connect} from "react-redux";

import elementsConstructor from './../../elementsConstructor'



function translateElement(Component){

  function TranslatedElement(props){

    const { id, type, selectedPage, dictionary, activeLanguage, global } = props

    // if activeLanguage is null default content is active
    if(!activeLanguage || dictionary === "loading") return <Component {...props}/>;

    let translation = null

    //if element is global , fetch global dictionary data
    if(global){
      translation = activeLanguage &&
                    dictionary.data[activeLanguage] &&
                    dictionary.data[activeLanguage].global.elements[id]
    }else{
      translation = activeLanguage &&
                    dictionary.data[activeLanguage] &&
                    dictionary.data[activeLanguage].pages[selectedPage] &&
                    dictionary.data[activeLanguage].pages[selectedPage].elements[id]
    }
    translation = translation ? translation : {}
    //check element constructor for content field names
    let elementContent = elementsConstructor[type].properties.filter((obj)=> obj.groupBy === "content")
    let translatedContent = {}


    elementContent.forEach((field)=>{
      translatedContent[field.id] = translation[field.id] ? translation[field.id] : props[field.id]
    })


    return <Component {...props} {...translatedContent}/>;
  }

  const mapStateToProps = ({ selectedElement, selectedPage, activeLanguage, dictionary}) => {
    return {

      activeLanguage,
      dictionary
    }
  }

  return connect(mapStateToProps, null)(TranslatedElement)

}

export default translateElement
