import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';

import { withStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import LeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import RightIcon from '@material-ui/icons/KeyboardArrowRight';

import editWrapper from './../wrappers/editWrapper'

import useMediaBreakPoint from './../../../hooks/useMediaBreakPoint'

import Element from './../../Element'


const styles = theme => ({
  root: {
    position: 'relative',
    width: "100%",
    height: "100%",
    minHeight: 100,
    overflow: "hidden"
  },
  absolute:{
    position:"absolute",
    top: 0,
    width: "100%",
    height: "100%"
  },
  column:{
    position: "relative",
    height:350,

    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center",
  },

  sliderContainer:{
    height: "100%",
    position:"absolute",
    left: 0,
    top: 0,
    display:"flex",
    flexDirection:"row",

    transition: "all .35s ease-in-out",
  },
  elementWrapper:{
    width:"100%",
    height:"100%",
    position:"relative",
  },
  element:{
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,
  },
  buttonLeftWrapper:{
    position: "absolute",
    top: 0,
    bottom: 0,
    left:0,

    display: "flex",
    flexDirection:"column",
    justifyContent:"center"
  },
  buttonLeft:{
    borderRadius: 0,
    background: "rgba(255,255,255,0.8)",
    zIndex:502,
  },
  buttonRightWrapper:{
    position: "absolute",
    top: 0,
    bottom: 0,
    right:0,

    display: "flex",
    flexDirection:"column",
    justifyContent:"center"
  },
  buttonRight:{
    borderRadius: 0,
    background: "rgba(255,255,255,0.8)",
    zIndex:502,
  },
  icon:{
    fontSize: 60,
    color: theme.palette.primary.main
  },
});




function CarouselWrapper (props){

  const [activeElement, setActiveElement] = React.useState(0)
  const [cicleCounter, setCicleCounter] = React.useState(0)
  const [width , setWidth] = React.useState(0)


  const mediaBreakPoint = useMediaBreakPoint()

  const { classes, children, disableButtons, fileUrl, edit, offset } = props;

  React.useEffect(()=>{

    updateWindowDimensions()
    window.addEventListener('resize', updateWindowDimensions)

    let intervalId = null

    //starts slideshow
    if(children && children.length > 1 && !edit){
      intervalId  = setInterval(()=>{
        setActiveElement(activeElement => (activeElement+1) % children.length)
        setCicleCounter(cicleCounter => cicleCounter + 1)
      }, 10000);
    }





    // componentWillUnmount
    return ()=>{
      window.removeEventListener('resize', updateWindowDimensions)
      clearInterval(intervalId);
    }

  },[])


  const updateWindowDimensions = () => {

    let containerWidth = props[mediaBreakPoint] ? props[mediaBreakPoint] : 12
    let spacing = offset ? offset : 0
    setWidth(((window.innerWidth)/12) * containerWidth - (edit ? spacing + 72 : spacing))
  }


  const handleLeftClick = ()=>{
    setActiveElement(activeElement-1)

  }

  const handleRightClick = ()=>{
    setActiveElement(activeElement + 1)


  }



  let numberOfElements = children ? children.length : 1

  let position = - activeElement * width

 
  if(width !== 0){
    return (

      <div className={classes.root}>
          {(!disableButtons || edit ) && activeElement !== 0 &&
            <div className={classes.buttonLeftWrapper}>
                <IconButton disabled={activeElement === 0} className={classes.buttonLeft} onClick={handleLeftClick}>
                   <LeftIcon className={classes.icon} />
                </IconButton>
            </div>
          }
          <div style={{width: `calc(${ width * numberOfElements}px)`, left: position ? position : 0}} className={classes.sliderContainer}>
            {fileUrl &&
              <div className={classes.absolute} style={{opacity: 0.2}}>
                <img alt="background" src={fileUrl} width="100%" height="100%"/>
              </div>
            }

            { children && children.map((child, i)=>{

              return(
                <Box key={i} width={`calc( 100% / ${numberOfElements})`} height="100%">
                  <Element key={child} edit={edit} elementId={child} animationActive={i === activeElement}/>
                </Box>
              )
            })}


          </div>
          {( !disableButtons || edit ) &&  activeElement !== numberOfElements-1 &&
            <div className={classes.buttonRightWrapper}>

                <IconButton disabled={activeElement === numberOfElements-1}  className={classes.buttonRight} onClick={handleRightClick}>
                   <RightIcon className={classes.icon} />
                </IconButton>

            </div>
          }
      </div>
    )
  }else return null


}

CarouselWrapper.propTypes = {

  classes: PropTypes.object.isRequired,
};

export default editWrapper(withStyles(styles)(CarouselWrapper));
