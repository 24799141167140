import {

  FETCH_PAGE_ELEMENTS,
  FETCH_GLOBAL_ELEMENTS,
  GLOBAL_ELEMENTS_LOADED,
  ADD_PAGE_ELEMENT,
  EDIT_PAGE_ELEMENT,
  EDIT_PAGE_ELEMENT_CONTENT,
  EDIT_PAGE_ELEMENT_CHILDREN,
  DELETE_PAGE_ELEMENT,

  SET_ACTIVE_MODAL,

  SET_SELECTED_ELEMENT,
  UPDATE_PAGE,
  ADD_CHANGE_PAGE_CHANGE_HISTORY,
  CLEAR_CHANGES_PAGE_CHANGE_HISTORY
} from './../../types'

import _ from 'lodash'

import * as supportFunctions from './../../supportFunctions'
import * as firebase from './../../../../config/firebase'

const saveFile = async (pageId, elementId, file) =>{
  let metadata = {
    cacheControl: 'public,max-age=31536000',
  };

  let uploadTask = firebase.imageStorage.child(file.name).put(file, metadata)



  let promise = new Promise((resolve, reject) => {
    uploadTask.on('state_changed', // or
    function(snapshot) {
     // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
     var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
     console.log('Upload is ' + progress + '% done');
     switch (snapshot.state) {
       case 'paused': // or 'paused'
         console.log('Upload is paused');
         break;
       case 'running': // or 'running'
         console.log('Upload is running');
         break;
     }
    }, function(error) {
        console.log(error)
    },function(){

      let downloadURL = uploadTask.snapshot.ref.getDownloadURL()


      resolve(downloadURL);
    })
  })


  return await promise
}

const savePdfFile = async (file) =>{

  let uploadTask = firebase.pdfStorage.child(file.name).put(file)

  let promise = new Promise((resolve, reject) => {
    uploadTask.on('state_changed', // or
    function(snapshot) {
     // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
     var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
     console.log('Upload is ' + progress + '% done');
     switch (snapshot.state) {
       case 'paused': // or 'paused'
         console.log('Upload is paused');
         break;
       case 'running': // or 'running'
         console.log('Upload is running');
         break;
     }
    }, function(error) {
        reject(error);
    },function(){
      let downloadURL = uploadTask.snapshot.ref.getDownloadURL()
      resolve(downloadURL);
    })
  })

  let downloadURL = await promise

  return downloadURL
}

export const addNewElement = (pageId, parentId, parent, newElement, elementPosition) => async dispatch =>{
  /// BUILD NEW PARENT CHILDREN ARRAY
  try{
    let parentChildren = []
    if(parent.children){
      parentChildren = elementPosition !== -1 ? [
        ...parent.children.slice(0, elementPosition),
        newElement.id,
        ...parent.children.slice(elementPosition),
      ] : [
        ...parent.children,
        newElement.id
      ]
    }else{
      parentChildren = [newElement.id]
    }


    /// CHECK IF ELEMENT HAS IMAGE TO UPLOAD
    if(newElement.fileUrl){
      newElement.fileName = newElement.fileUrl.name
      newElement.fileUrl = await saveFile(pageId, newElement.id, newElement.fileUrl)
      firebase.imagesRef.push().set({imageUrl: newElement.fileUrl})
    }

    if(newElement.imageSrc){
      newElement.fileUrl = newElement.imageSrc
      newElement.imageSrc = null
    }

    if(newElement.global || parent.global){
      // if parent element is global, his child element must be global element too
      if(parent.global) newElement.global = true

      if(newElement.parent === "root"){
        /*
        firebase.databaseRef.update({
          [`pages/${pageId}/elements/root/children`]: parentChildren,
          [`globalElements/${newElement.id}`]: newElement
        })
        */
        dispatch({
          type: ADD_CHANGE_PAGE_CHANGE_HISTORY,
          payload: {
            [`pages/${pageId}/elements/root/children`]: parentChildren,
            [`globalElements/${newElement.id}`]: newElement
          }
        })
      }else{
        /*
        firebase.globalElementsRef.update({
          [`/${parentId}/children`]: parentChildren,
          [`/${newElement.id}`]: newElement
        })
        */
        dispatch({
          type: ADD_CHANGE_PAGE_CHANGE_HISTORY,
          payload: {
            [`/${parentId}/children`]: parentChildren,
            [`/${newElement.id}`]: newElement
          }
        })
      }
    }else{
      /*
      firebase.pagesRef.child(pageId).update({
        [`/elements/${parentId}/children`]: parentChildren,
        [`/elements/${newElement.id}`]: newElement
      })
      */
      dispatch({
        type: ADD_CHANGE_PAGE_CHANGE_HISTORY,
        payload: {
          [`/elements/${parentId}/children`]: parentChildren,
          [`/elements/${newElement.id}`]: newElement
        }
      })
    }



    dispatch({
      type: ADD_PAGE_ELEMENT,
      pageId,
      parentId,
      parentChildren,
      newElement
    })




    return true
  }catch(error){

    console.log(error)
    return false
  }


}



export const editElement =  (pageId, parentId, parent, editedElement, elementPosition) => async (dispatch) => {
  console.log("editedElement")
  
  try {

    if(editedElement.fileUrl){
      /// save file to server and return file url

      if(typeof editedElement.fileUrl !== "string"){
        editedElement.fileName = editedElement.fileUrl.name
        editedElement.fileUrl = await saveFile(pageId, editedElement.id, editedElement.fileUrl)
        firebase.imagesRef.push().set({imageUrl: editedElement.fileUrl})
      }

    }

    if(editedElement.imageSrc){
      editedElement.fileUrl = editedElement.imageSrc
      editedElement.imageSrc = null
    }

    if(editedElement.articles){
      for(let i = 0; i < editedElement.articles.length; i++){
        let article = editedElement.articles[i]

        if(article.pdfFile){
          article.pdfUrl = await savePdfFile(article.pdfFile)
          article.pdfFile = null
        }
        // if fileUrl is object File, save it to storage and fetch stringURL insted
        if(article.fileUrl instanceof Object){
          article.fileUrl = await saveFile(null , null, article.fileUrl)
          article.file = null
        }
      }

    }

    /// set element position ---> if elementPosition is -1, put elemenet at the end
    let parentChildren = parent.children ? parent.children : null
    let currElementPosition = parent && parent.children && parent.children.indexOf(editedElement.id)

    if(parent.children && currElementPosition !== elementPosition){
      parentChildren =  parent.children.map((value, i)=> value === editedElement.id ? null : value  )
      parentChildren = [...parentChildren.slice(0, elementPosition), editedElement.id, ...parentChildren.slice(elementPosition)]
      parentChildren = parentChildren.filter((value, i)=> value !== null )
    }


    if(editedElement.global || parent.global){
      if(editedElement.parent === "root"){
     
        dispatch({
          type: ADD_CHANGE_PAGE_CHANGE_HISTORY,
          payload: {
            [`pages/${pageId}/elements/root/children`]: parentChildren,
            [`globalElements/${editedElement.id}`]: editedElement
          }
        })
      }else{
      
        dispatch({
          type: ADD_CHANGE_PAGE_CHANGE_HISTORY,
          payload: {
            [`/${parentId}/children`]: parentChildren,
            [`/${editedElement.id}`]: editedElement
          }
        })
      }
    }else{
    
      dispatch({
        type: ADD_CHANGE_PAGE_CHANGE_HISTORY,
        payload: {
          [`/elements/${editedElement.id}`]: editedElement,
          [`/elements/${parentId}/children`]: parentChildren,
        }
      })
    }


    dispatch({
      type: EDIT_PAGE_ELEMENT,
      pageId,
      parentId,
      parentChildren,
      editedElement,

    })

    return true
  } catch(error) {
    console.log(error)
    return false
  }

}

export const editElementContent = (pageId, editedElement) => async dispatch => {
  try {
    /// set element position ---> if elementPosition is -1, put elemenet at the end
    if(editedElement.file){
      /// save file to server and return file url
      editedElement.fileName = editedElement.file.name
      editedElement.fileUrl = await saveFile(pageId, editedElement.id, editedElement.file)
      delete editedElement.file
    }
    if(editedElement.imageSrc){
      editedElement.fileUrl = editedElement.imageSrc
      editedElement.imageSrc = null
    }
    /*
    await firebase.pagesRef.child(pageId).update({
        [`/elements/${editedElement.id}`]: editedElement,
      })
    */
    dispatch({
      type: ADD_CHANGE_PAGE_CHANGE_HISTORY,
      payload: {
        [`/elements/${editedElement.id}`]: editedElement,
      }
    })

    dispatch({
      type: EDIT_PAGE_ELEMENT_CONTENT,
      pageId,
      editedElement,
    })

    return true
  } catch(error) {
    console.log(error)
    return false
  }
}


export const copyElement = (selectedElement, selectedPage, copyFromPageId, copyToPageId, copyToElementId) => async (dispatch, getState) =>{
  console.log("copyElement")
  let elements = _.cloneDeep(getState().elements)
  if(copyFromPageId !== copyToPageId) {
    let copyToPageIdElements = _.cloneDeep(getState().webpages[copyFromPageId].elements)
    delete copyToPageIdElements.root
    elements = {...elements, ...copyToPageIdElements}
  }
  let copiedElement = _.cloneDeep(elements[selectedElement])
  //console.log({elements, stateElementes: getState().elements, copiedElement, selectedElement})
  /*
  let copiedElement = copyFromPageId === copyToPageId ?
                                    _.cloneDeep(getState().elements[selectedElement]):
                                    _.cloneDeep(getState().webpages[copyFromPageId].elements[selectedElement])
                                    */
  // if element parent  has changed, it sets new one equal to copyToElementId
  //console.log({copiedElement, selectedElement, selectedPage, copyFromPageId, copyToPageId, copyToElementId, elements: getState().webpages[selectedPage].elements})
  copiedElement.parent = copyToElementId

  let copiedElementChildren = supportFunctions.copyElementTree(_.cloneDeep(elements), copiedElement, null)
  let rootElementId = supportFunctions.findRootElementId(copiedElementChildren)

  let newCopiedElements = {}

  Object.entries(copiedElementChildren).forEach(([elementId, element])=>{

    newCopiedElements = {...newCopiedElements, [/elements/+elementId]:element}
  })
  console.log(newCopiedElements)
  /// build new parent child tree, with new child id
  let parent = getState().elements[copyToElementId]
  let parentChildren = [...parent.children, rootElementId]
  // if copying to another page and selecting root element as parent, there is wrrong root element in elements store. So we need to fetch right one from webpages store.
  if(selectedPage !== copyToPageId && copyToElementId === "root"){
    parentChildren = [...getState().webpages[copyToPageId].elements[copyToElementId].children, rootElementId]
  }

  if(copiedElement.global){
    if(copyToElementId === "root")parentChildren = [...getState().webpages[copyToPageId].elements[copyToElementId].children, selectedElement]
    /*
    await firebase.pagesRef.child(copyToPageId).update({
      [`/elements/${copyToElementId}/children`]: parentChildren,
    })
    */

    dispatch({
      type: ADD_CHANGE_PAGE_CHANGE_HISTORY,
      payload: {
        [`/elements/${copyToElementId}/children`]: parentChildren,
      }
    })
  }else{
    /*
    firebase.pagesRef.child(copyToPageId).update({
      [`/elements/${copyToElementId}/children`]: parentChildren,
      ...newCopiedElements
    }).then(()=>{
      dispatch({
        type: EDIT_PAGE_ELEMENT,
        parentId: copyToElementId,
        parentChildren,
        editedElement: getState().elements[copyToElementId],

      })
      dispatch({
        type: FETCH_PAGE_ELEMENTS,
        payload: copiedElementChildren
      })

      dispatch({
        type: UPDATE_PAGE,
        pageId: selectedPage,
        payload: copiedElementChildren
      })
    })
    */
    parent.children = parentChildren
    let updatedElements = {
      parent,
      ...copiedElementChildren
    }
    dispatch({
      type: ADD_CHANGE_PAGE_CHANGE_HISTORY,
      payload: {
        [`/elements/${copyToElementId}/children`]: parentChildren,
        ...newCopiedElements
      }
    })

    dispatch({
      type: FETCH_PAGE_ELEMENTS,
      payload: updatedElements
    })



  }

  return rootElementId
}



export const deleteSelectedElement = (selectedElementId) => async (dispatch, getState) => {

  let state = getState()

  let selectedElement = state.elements[selectedElementId]
  let selectedPage = state.selectedPage

  let parentId = state.elements[selectedElementId].parent
  let parent = state.elements[parentId]

  /// REMOVE ELEMENT FROM PARENT
  let parentChildren = parent.children.filter((child)=> child !== selectedElementId)

  /// FIND ALL ELEMENT CHILDREN
  let selectedElementChildren = {}
  if(selectedElement.children){
    supportFunctions.getAllElementChildren(state.elements, selectedElement, []).forEach((childId)=>{
      selectedElementChildren = {...selectedElementChildren, [`/elements/${childId}`]: null}
    })
  }
  /*
  firebase.pagesRef.child(selectedPage).update({
      ...selectedElementChildren,
      [`/elements/${selectedElementId}`]: null,
      [`/elements/${parentId}/children`]: parentChildren,
  }).then(()=>{
    dispatch({
      type: SET_SELECTED_ELEMENT,
      payload: parentId
    })

    dispatch({
      type: DELETE_PAGE_ELEMENT,
      elementId:  selectedElement,
      pageId: selectedPage,
      parentId,
      parentChildren
    })

    dispatch({
      type: SET_ACTIVE_MODAL,
      payload: ""
    })
  }).catch((error)=>{
    console.log(error)
  })
  */
  dispatch({
    type: ADD_CHANGE_PAGE_CHANGE_HISTORY,
    payload: {
      ...selectedElementChildren,
      [`/elements/${selectedElementId}`]: null,
      [`/elements/${parentId}/children`]: parentChildren,
    }
  })

  dispatch({
    type: SET_SELECTED_ELEMENT,
    payload: parentId
  })

  dispatch({
    type: DELETE_PAGE_ELEMENT,
    elementId:  selectedElement,
    pageId: selectedPage,
    parentId,
    parentChildren
  })


}


// moveFor(int) ---> move element for X steps right if positive or left if negative
export const switchElementPositionInsideParent = (pageId, elementId, moveFor) => async (dispatch, getState) => {
  let element = getState().elements[elementId]
  let elementParent = getState().elements[element.parent]

  let parentChildren = [...elementParent.children]


  let elementCurrentPosition = parentChildren.indexOf(elementId)

  // remove element from current position
  parentChildren[elementCurrentPosition] = null

  let sliceArrayLeft,sliceArrayRight

  if(moveFor > 0){
    sliceArrayLeft = elementCurrentPosition+moveFor+1
    sliceArrayRight = elementCurrentPosition+moveFor+1
  }else{
    sliceArrayLeft = elementCurrentPosition+moveFor
    sliceArrayRight = elementCurrentPosition+moveFor
  }

  sliceArrayLeft = sliceArrayLeft < 0 ? 0 : sliceArrayLeft
  sliceArrayLeft =  sliceArrayLeft > parentChildren.length ? parentChildren.length : sliceArrayLeft


  sliceArrayRight = sliceArrayRight < 0 ? 0 : sliceArrayRight
  sliceArrayRight = sliceArrayRight > parentChildren.length ? parentChildren.length : sliceArrayRight


  //combine arrays and insert element on its new position
  parentChildren = [...parentChildren.slice(0, sliceArrayLeft),
                    elementId,
                    ...parentChildren.slice(sliceArrayRight, parentChildren.length)]


  //remove null values
  parentChildren = parentChildren.filter(o=>o)

  /*
  firebase.pagesRef.child(pageId).update({
      [`/elements/${elementParent.id}/children`]: parentChildren,
  }).then(()=>{
    dispatch({
      type: EDIT_PAGE_ELEMENT_CHILDREN,
      elementId: elementParent.id,
      children: parentChildren,
    })
  }).catch((err)=>{
    console.log(err)
  })
  */
  dispatch({
    type: ADD_CHANGE_PAGE_CHANGE_HISTORY,
    payload: {
      [`/elements/${elementParent.id}/children`]: parentChildren,
    }
  })

  dispatch({
    type: EDIT_PAGE_ELEMENT_CHILDREN,
    elementId: elementParent.id,
    children: parentChildren,
  })
}

export const fetchGlobalElements = ()=> async dispatch => {
  console.log("fetchGlobalElements")
  let elements = await firebase.globalElementsRef.once("value")
  elements = elements.val()


  dispatch({
    type: FETCH_PAGE_ELEMENTS,
    payload: elements
  })

  dispatch({
    type: FETCH_GLOBAL_ELEMENTS,
    payload: elements
  })

  dispatch({
    type: GLOBAL_ELEMENTS_LOADED,
  })
}


function setValue(fields, val, obj) {
  console.log(fields)
  var result = obj;
  for (var i = 0, n = fields.length; i < n && result !== undefined; i++) {
    var field = fields[i];
    if (i === n - 1) {
      result[field] = val;
    } else {
      if (typeof result[field] === 'undefined' || !_.isObject(result[field])) {
        result[field] = {};
      }
      result = result[field];
    }
  }
}

export const saveElementsChanges = () => async (dispatch, getState) =>{

  const elements = getState().elements
  const selectedPage = getState().selectedPage
  let pageChangeHistory = getState().pageChangeHistory.changes.reduce((acc, change)=>{

    Object.keys(acc).forEach( pathA =>{
      Object.keys(change).forEach( pathC =>{

        if(pathC.includes(pathA) && pathC.length > pathA.length){
          //console.log({pathC, pathA, "pathCPathA": pathC.replace("/elements/","").split("/"), value:change[pathC],acc })
          setValue(pathC.replace(pathA+"/","").split("/"), _.clone(change[pathC]), acc[pathA])
          delete change[pathC]
        }else if(pathA.includes(pathC) && pathC.length < pathA.length) delete acc[pathA]
      })
    })

    return {...acc, ...change}
  }, {})
  //console.log({pageChangeHistory})

  let elementsChanges = {}
  
  Object.keys(pageChangeHistory).forEach((key)=>{
    /// element id will be always in 3th place in array
    let elementId = key.split("/")[2]
    console.log( key, key.split("/")[2])
    //check if element is global element
    if(elements[elementId] && elements[elementId].global){
        elementsChanges = {...elementsChanges, [`/globalElements${key.replace("/elements", "")}`]: pageChangeHistory[key]}
    }else{
        elementsChanges = {...elementsChanges, [`/pages/${selectedPage}${key}`]: pageChangeHistory[key]}
    }

  })

 

  try{
    await firebase.databaseRef.update(elementsChanges)

    dispatch({
      type: CLEAR_CHANGES_PAGE_CHANGE_HISTORY
    })
  }catch(err){
    console.log(err)
  }

  console.log(elementsChanges)
}
