import * as defaultCss from './../../defaultCss'

/*
####################################################
###################################################
##################VARIABLES#######################

name(String) - container description
childrens(Array) - contains ids of possible children inputs
properties(Array) - container properties
  label - property description
  type[text, selection, selectionArray, checkbox] - input type
  id - input id
  value - input value
  if(type === selection) values - select input values
  default - default input value
  linkedTo - id of input that it is linked to
  linkedValue - value shown in selection
  valuesQuery - ?
  basic - basic inputs (for switching between advanced and normal mode)
  groupBy[content, look, function, image] - for easier grouping of inputs inside forms
*/


const DEFAULT_CSS_CLASSES = defaultCss.allClasses
const GRID_VALUES = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
const FLEX_ALIGMENTS = [
  "flex-start",
  "flex-end",
  "center",
  "space-between",
  "space-around"
]
const icons = [
  "shopping_cart",
  "location_on",
  "settings",
  "invert_colors",
  "chrome_reader_mode"
]

const custom_element_tehnofan_navigation_dropdown = {
  name:"Tehnofan navigation dropdown",
  children:[
    "button_element",
    "custom_element_tehnofan_navigation_dropdown"
  ],
  properties:[
    {
      label:"Name", type:"text", id: "name", value: "", default: "", groupBy: "function"
    },
    {
      label:"Label", type:"text", id: "label", value: "", default: "", basic: true, groupBy: "content"
    },

    {
      label:"Classes", type:"selection", id: "classNames", values: DEFAULT_CSS_CLASSES, default: [], groupBy: "look"
    },
    {
      label:"Styles", type:"selectionArray", id: "style", values: [], default: [], groupBy: "look"
    },
    {
      label: "Button type", type:"selection", id: "buttonType", values: [ "Redirect", "Path"], default: "Path", basic: true, groupBy: "function"
    },
    {
      label:"Redirect to", id: "redirect", linkedTo: "buttonType", linkedValue:"Redirect", type:"text",  value: "", default: "", basic: true, groupBy: "function"
    },
    {
      label:"Go to", id: "path", linkedTo: "buttonType", linkedValue: "Path", type:"selection",  valuesQuery: "allpages", default: "", basic: true, groupBy: "function"
    }
  ],
}


const custom_element_tehnofan_navigation = {
  name:"Tehnofan navigation",
  children:[
    "button_element",
    "custom_element_tehnofan_navigation_dropdown"
  ],
  properties:[
    {
      label:"Name", type:"text", id: "name", value: "", default: "", groupBy: "function"
    },
    {
      label:"Global element", type:"checkbox", id: "global", value: "", default: "", groupBy: "function"
    },
    {
      label:"Logo", type:"fileUpload", id: "fileUrl", value: "", default: "", basic: true, groupBy: "image"
    },
    {
      label:"Classes", type:"selection", id: "classNames", values: DEFAULT_CSS_CLASSES, default: [], groupBy: "look"
    },
    {
      label:"Styles", type:"selectionArray", id: "style", values: [], default: [], groupBy: "look"
    },


  ],
}

const custom_element_tehnofan_carousel = {
  name:"Tehnofan carousel",
  children:[
    "grid_item",
    "grid_container",
    "custom_element_tehnofan_carousel_image_button"
  ],
  properties:[
    {
      label:"Name", type:"text", id: "name", value: "", default: ""
    },
  ],
}
const custom_element_tehnofan_carousel_image_button = {
  name:"Tehnofan carousel image button",

  properties:[
    {
      label:"Name", type:"text", id: "name", value: "", default: ""
    },
    {
      label:"Title", type:"text", id: "title", value: "", default: ""
    },
    {
      label:"Label", type:"text", id: "label", value: "", default: ""
    },
    {
      label:"Image", type:"fileUpload", id: "fileUrl", value: "", default: ""
    },
  ],
}

const custom_element_tehnofan_circle_image_button = {
  name:"Tehnofan circle image button",

  properties:[
    {
      label:"Name", type:"text", id: "name", value: "", default: "", groupBy: "function"
    },
    {
      label:"Title", type:"text", id: "title", value: "", default: "", basic: true, groupBy: "content"
    },

    {
      label:"Image", type:"fileUpload", id: "fileUrl", value: "", default: "", basic: true, groupBy: "image"
    },

    {
      label: "Icon", type:"selection", id: "icon", values: icons, default: "", groupBy: "look"
    },

    {
      label:"Animation durration", type:"text", id: "animationDurration", value: "", default: 1000, groupBy: "look"
    },

    {
      label:"Label styles", type:"selectionArray", id: "titleStyle", values: [], default: [], groupBy: "look"
    },
    {
      label:"Large devices", type:"selection", id: "lg", values: GRID_VALUES, default: 12, groupBy: "look"
    },
    {
      label:"Medium devices", type:"selection", id: "md", values: GRID_VALUES, default: 12, groupBy: "look"
    },
    {
      label:"Small devices", type:"selection", id: "sm", values: GRID_VALUES, default: 12, groupBy: "look"
    },
    {
      label:"Extra small devices", type:"selection", id: "xs", values: GRID_VALUES, default: 12, groupBy: "look"
    },
    {
      label: "Button type", type:"selection", id: "buttonType", values: [ "Redirect", "Path"], default: "Path", basic: true, groupBy: "function"
    },
    {
      label:"Redirect to", id: "redirect", linkedTo: "buttonType", linkedValue:"Redirect", type:"text",  value: "", default: "", basic: true, groupBy: "function"
    },
    {
      label:"Go to", id: "path", linkedTo: "buttonType", linkedValue: "Path", type:"selection",  valuesQuery: "allpages", default: "", basic: true, groupBy: "function"
    },
  ],
}

const custom_element_tehnofan_image_button = {
  name:"Tehnofan image button",

  properties:[
    {
      label:"Name", type:"text", id: "name", value: "", default: ""
    },
    {
      label:"Title", type:"text", id: "title", value: "", default: ""
    },

    {
      label:"Image", type:"fileUpload", id: "fileUrl", value: "", default: ""
    },
    {
      label:"Large devices", type:"selection", id: "lg", values: GRID_VALUES, default: 12
    },
    {
      label:"Medium devices", type:"selection", id: "md", values: GRID_VALUES, default: 12
    },
    {
      label:"Small devices", type:"selection", id: "sm", values: GRID_VALUES, default: 12
    },
    {
      label:"Extra small devices", type:"selection", id: "xs", values: GRID_VALUES, default: 12
    },


  ],
}

const custom_element_tehnofan_content = {
  name : "Tehnofan content",

  properties:[
    {
      label:"Name", type:"text", id: "name", value: "", default: "", groupBy: "function"
    },
    {
      label:"Title", type:"text", id: "title", value: "", default: "", basic: true, groupBy: "content"
    },
    {
      label:"Background image", type:"fileUpload", id: "fileUrl", value: "", default: "", basic: true, groupBy: "image"
    },

    {
      label:"Content alignment", type:"selection", id: "contentAlignment", values: FLEX_ALIGMENTS, default: "center", groupBy: "look"
    },


    {
      label:"Styles", type:"selectionArray", id: "style", values: [], default: [], groupBy: "look"
    },
    {
      label:"Position in document", id: "documentPosition", type:"selection", values:[0,1,2,3,4,5],  value: 0, default: 0, groupBy: "animation"
    },
  ],
}

const custom_element_tehnofan_home_content = {
  name : "Tehnofan home content",

  properties:[
    {
      label:"Name", type:"text", id: "name", value: "", default: "", groupBy: "function"
    },
    {
      label:"Title", type:"text", id: "title", value: "", default: "", basic: true, groupBy: "content"
    },

    {
      label:"Styles", type:"selectionArray", id: "style", values: [], default: [], groupBy: "look"
    },



  ],
}

const custom_element_tehnofan_footer = {
  name:"Tehnofan footer",

  properties:[
    {
      label:"Name", type:"text", id: "name", value: "", default: "", groupBy: "function"
    },

  ],
}


const custom_element_tehnofan_water_articles = {
  name:"Tehnofan water articles",

  properties:[
    {
      label:"Name", type:"text", id: "name", value: "", default: "", groupBy: "function"
    },

    {
      label:"Articles", type:"articleArray", id: "articles", values: [], default: [], basic: true, groupBy: "article"
    },
    {
      label:"Styles", type:"selectionArray", id: "style", values: [], default: [], groupBy: "look"
    },
    {
      label:"With modal window", type:"checkbox", id: "withModal", value: "", default: "", groupBy: "function"
    },
  ],
}

const custom_element_tehnofan_contacts = {
  name:"Tehnofan contacts",

  properties:[
    {
      label:"Name", type:"text", id: "name", value: "", default: ""
    },
    {
      label:"Title", type:"text", id: "title", value: "", default: "", basic: true, groupBy: "content"
    },
    {
      label:"Address", type:"text", id: "address", value: "", default: "", basic: true, groupBy: "content"
    },
    {
      label:"Phone", type:"text", id: "phone", value: "", default: "", basic: true, groupBy: "content"
    },
    {
      label:"Mobile", type:"text", id: "mobile", value: "", default: "", basic: true, groupBy: "content"
    },
    {
      label:"Email", type:"text", id: "email", value: "", default: "", basic: true, groupBy: "content"
    },

    {
      label:"Google API Key", type:"text", id: "googleApiKey", value: "", default: "", groupBy: "function"
    },
    {
      label:"Styles", type:"selectionArray", id: "style", values: [], default: [], groupBy: "look"
    },
    {
      label:"Background image", type:"fileUpload", id: "fileUrl", value: "", default: "", basic: true, groupBy: "image"
    },

  ],
}

const custom_element_tehnofan_tldr = {
  name:"Tehnofan tldr",

  properties:[
    {
      label:"Name", type:"text", id: "name", value: "", default: ""
    },
    {
      label:"Title", type:"text", id: "title", value: "", default: "", basic: true, groupBy: "content"
    },
    {
      label:"Styles", type:"selectionArray", id: "style", values: [], default: [], groupBy: "look"
    },
  ],


}

const custom_element_tehnofan_subscription = {
  name:"Tehnofan subscription",

  properties:[
    {
      label:"Name", type:"text", id: "name", value: "", default: ""
    },

    {
      label:"Styles", type:"selectionArray", id: "style", values: [], default: [], groupBy: "look"
    },
  ],


}

export default {
  custom_element_tehnofan_navigation,
  custom_element_tehnofan_navigation_dropdown,
  custom_element_tehnofan_carousel,
  custom_element_tehnofan_carousel_image_button,
  custom_element_tehnofan_circle_image_button,
  custom_element_tehnofan_image_button,
  custom_element_tehnofan_footer,
  custom_element_tehnofan_content,
  custom_element_tehnofan_home_content,
  custom_element_tehnofan_water_articles,
  custom_element_tehnofan_contacts,
  custom_element_tehnofan_tldr,
  custom_element_tehnofan_subscription
}
