import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';

const CustomInputComponent = ({
  label,
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => (
  <FormControl error={(errors[field.name] && touched[field.name]) ? true : false} fullWidth={props.fullWidth ? props.fullWidth : true}>
    <InputLabel htmlFor="component-error">{label}</InputLabel>
      <Input {...field} {...props}/>
    {errors[field.name] &&
      touched[field.name] && <FormHelperText id="component-error-text">{errors[field.name]}</FormHelperText>}
  </FormControl>

);

export default CustomInputComponent
