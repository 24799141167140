import React, {Suspense} from 'react';

import * as actions from './redux/actions'
import {connect} from "react-redux";

import PropTypes from 'prop-types';

import {withRouter} from 'react-router'
import {Route, Switch} from 'react-router-dom'

import {withStyles} from '@material-ui/core/styles';
import withRoot from './withRoot';


import CookieBar from './CookieBar'

import LoginPage from './login/LoginPage'
import PageView from './webElements/PageView'

import Preloader from './webElements/default/preloaders/Preloader'

const ControlPanel = React.lazy(()=> import('./controlpanel/ControlPanel'))

const styles = theme => ({

  root: {
    minHeight: "100vh",
    position: "relative",
    textAlign: 'center'
  },

  backgroundImage: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%"
  }

});

class App extends React.Component {

  state = {
    pageLoaded: false
  }

  componentDidMount() {
    this.props.isAuthenticated()
    //this.props.fetchGoogleLoginRedirectResult()

    this.props.fetchGlobalElements()
    this.props.fetchDictionaryLanguages()

    this.setState({pageLoaded: true})

  }

  render() {
    const {classes} = this.props;

    return (<div className={classes.root}>
      <Switch>
        <Route path="/controlpanel/login" component={LoginPage}/>

        <Route path="/controlpanel" render={() => (
             <Suspense fallback={<Preloader />}>
               <ControlPanel/>
             </Suspense>
          )}/>

        <Route path="/" render={() => (
          <React.Fragment>
            <PageView/>
            <CookieBar/>
          </React.Fragment>)}/>
      </Switch>
    </div>);

  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = () => {
  return {}
}

export default withRouter(connect(mapStateToProps, actions)(withRoot(withStyles(styles)(App))))
//<img alt="tears" src={WATER_DROPS} className={classes.backgroundImage} />
