
export const fetchSearchedResults = (searchedString) => async dispatch => {
  console.log("fetchSearchedResults")

  const options = {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({searchedString})
  }

  const res = await fetch('/functions/search', options)

  const results = await res.json()

  return results
}