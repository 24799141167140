import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Element from './../../../Element'
import * as tehnofanCss from './../tehnofanCss'
import * as defaultCss from './../../../defaultCss'

import classNames from 'classnames'


const styles = theme => ({

  ...defaultCss.styles(theme),
  root: {
    position: "relative",

  },
  column:{
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems: "center",

  },
  title:{

    padding: theme.spacing.unit *3
  },
  container:{
    height: "100%",
    position: "relative",
    padding: theme.spacing.unit * 3
  }

});



function Tldr(props) {
  const { classes, title, children, style, edit, selectedPage } = props;

  React.useEffect(()=>{
    if(!edit && props.location.search){
      let params = new URLSearchParams(props.location.search)
      let elementId = params.get("scroll")
      var element = document.getElementById(elementId);
      element.scrollIntoView(true);
      window.scrollBy(0, -150)
    }

  })

  return (
    <div style={style ?  style.reduce((obj, tmp)=> {return {...obj, [tmp.name]: tmp.value}},{}) : null}  className={classes.root}>
      <Grid container justify="center">
        <Grid item md={12} lg={11}>
          <div className={classNames(classes.container, classes.column)}>
            {children && children.map((child)=> <Element key={child} edit={edit} selectedPage={selectedPage} elementId={child}/>)}
          </div>
        </Grid>
      </Grid>

    </div>
  );
}

Tldr.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(Tldr))
