import * as firebase from 'firebase/app';
import 'firebase/analytics'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/storage'
import 'firebase/functions'
//import analytics from 'firebase/analytics'


import { FirebaseConfig } from "../config/keys";

firebase.initializeApp(FirebaseConfig);


export const databaseRef = firebase.database().ref()

export const storageRef = firebase.storage().ref()

export const authRef = firebase.auth()
export const authProviderRef = firebase.auth.GoogleAuthProvider

export const serverTimestamp = firebase.database.ServerValue.TIMESTAMP

export const userInfoRef = databaseRef.child("users")

export const pagesRef = databaseRef.child("pages")
export const pagesInfoRef = databaseRef.child("pagesInfo")

export const globalElementsRef = databaseRef.child("globalElements")

export const subscriptions = databaseRef.child("subscriptions")
export const usersRef = databaseRef.child("users")

export const dictionaryRef = databaseRef.child("dictionary")

export const imagesRef = databaseRef.child("images")

/// FUNCTION REFS ///
export const functions = firebase.functions

/// STORAGE REFS ////
export const imageStorage = storageRef.child("images")
export const pdfStorage = storageRef.child("pdfs")


// ANALYTICS REFS ////
export const analytics = firebase.analytics()
