import React from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';

import { Formik } from 'formik';
import * as Yup from 'yup';

import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

import * as actions from './../../redux/actions'
const styles = theme => ({

  root: {

    width:"100%"
  },


});

class SubscriptionInput extends React.Component {

  render() {
    const { classes } = this.props;

    return (
        <div className={classes.root}>
          <Formik
            initialValues={{ search: ''}}
            onSubmit={async (values, actions) => {

            }}
            validationSchema={Yup.object().shape()}
          >
            {props => {
              const {
                values,
                status,
                touched,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
              } = props;

              return (
                <form onSubmit={handleSubmit}>
                  <FormControl error={Boolean(errors.email)} fullWidth>
                    <InputLabel htmlFor="component-error">Išči</InputLabel>
                    <Input
                      id="search"
                      value={values.email}
                      
                      onChange={handleChange}
                      onBlur={handleBlur}
                      aria-describedby="component-error-text"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton type="submit"
                            aria-label="submit"
                          >
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                    />

                  </FormControl>
                </form>
              );
            }}
          </Formik>
        </div>
    );

  }
}

SubscriptionInput.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = () => {
  return {}
}

export default withStyles(styles)(connect(mapStateToProps, actions)(SubscriptionInput))
//<img alt="tears" src={WATER_DROPS} className={classes.backgroundImage} />
