import React from 'react';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import * as defaultCss  from './../../defaultCss'

import classNames from 'classnames'

import editWrapper from './../wrappers/editWrapper'

import Element from './../../Element'

const styles = theme => ({
  ...defaultCss.styles(theme),
  root:{

  },
  active:{
    border: "2px solid red",
  }
});


function BoxWrapper(props){

  const {
    classes,
    edit,
    style,
    children,
    fileUrl,
    animationActive
  } = props

  return (

      <Box
        display="flex"
        flexDirection="row"
        width="100%"
        height= "100%"
        position="relative"
        className={classNames(classes.root, classes[props.classNames])}
        style={style ?  style.reduce((obj, tmp)=> {return {...obj, [tmp.name]: tmp.value}},{}) : {}}
      >
        {
          fileUrl &&
          <Box position="absolute" width="100%" height="100%">
            <img alt="background" src={fileUrl} width="100%" height="100%" />
          </Box>
        }
        { children && children.map((child, i)=>{
          return  <Element key={child} edit={edit} elementId={child} animationActive={animationActive} />
        })}
      </Box>

  )

}

BoxWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(editWrapper(BoxWrapper))
