import React from 'react';

import { connect } from "react-redux";
import { withRouter } from 'react-router'
import * as actions from './../../../redux/actions'
import PropTypes from 'prop-types';


import { withStyles } from '@material-ui/core/styles';

import Fade from '@material-ui/core/Fade';

import classNames from 'classnames'
import * as defaultCss  from './../../defaultCss'

import editWrapper from './../wrappers/editWrapper'

const styles = theme => ({
  ...defaultCss.styles(theme),

  root:{
    width: "100%",
    height: "auto",
  }
});


class ImageWrapper extends React.Component {


  handleClick = ()=>{
    if(this.props.redirect)window.location.href = this.props.redirect
  }

  render() {
    const { classes, style, fileUrl, fileName, edit, activeDocumentPage, documentPosition} = this.props

    if(edit || (documentPosition ? (documentPosition <= activeDocumentPage) : true)){
      return (
        <Fade in={true} timeout={2000} mountOnEnter unmountOnExit>
          <img
            onClick={!edit ? this.handleClick : null}
            className={classes[this.props.classNames] ? classes[this.props.classNames] : classes.root}
            src={fileUrl}
            alt={fileName}
            style={style ?  style.reduce((obj, tmp)=> {return {...obj, [tmp.name]: tmp.value}},{}) : {}}
          />
        </Fade>
      )
    }else return null

  }
}

ImageWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = ({ activeDocumentPage }) => {
  return {
    activeDocumentPage
  }
}

export default withRouter(connect(mapStateToProps, actions)(editWrapper(withStyles(styles)(ImageWrapper))))
