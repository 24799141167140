import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress';

const styles = theme => ({
  root: {
    width: "100vw",
    height: "100vh",

    display: "flex",
    flexDirection: "column",

    justifyContent: "center",
    alignContent: "center"
  },

  // Reproduce the Facebook spinners.
  facebook: {
    margin: theme.spacing.unit * 2,
    position: 'relative',
  },
  facebook1: {
    color: '#eef3fd',
  },
  facebook2: {
    color: '#104076',
    animationDuration: '550ms',
    position: 'absolute',
    left: 0,
  },
});

const SPINNER_SIZE = 80

function Preloader(props) {
  const { classes } = props;
  return (
    <div className={classes.root}>
        <div style={{position:"relative", width: SPINNER_SIZE, margin: "auto" }}>
            <CircularProgress
            variant="determinate"
            value={100}
            className={classes.facebook1}
            size={SPINNER_SIZE}
            thickness={4}
          />
          <CircularProgress
            variant="indeterminate"
            disableShrink
            className={classes.facebook2}
            size={SPINNER_SIZE}
            thickness={4}
          />
        </div>
    </div>
  );
}

Preloader.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Preloader);
