import {
  UNDO_PAGE_CHANGE_HISTORY,
  REDO_PAGE_CHANGE_HISTORY,
  UPDATE_ELEMENTS_FROM_CHANGES,
  CLEAR_CHANGES_PAGE_CHANGE_HISTORY
} from './../types'



export const undoPageChangeHistory = () => (dispatch, getState) =>{
  const selectedPage = getState().selectedPage
  const webpageElements = getState().webpages[selectedPage].elements
  const pageChangeHistory = getState().pageChangeHistory
  const changes = pageChangeHistory.changes.filter((o,i)=> i !== pageChangeHistory.changes.length - 1)


  dispatch({
    type: UPDATE_ELEMENTS_FROM_CHANGES,
    changes,
    webpageElements
  })
  //console.log(webpageElements)
  dispatch({
    type: UNDO_PAGE_CHANGE_HISTORY,
  })
}

export const redoPageChangeHistory = () => (dispatch, getState) =>{
  const selectedPage = getState().selectedPage
  const webpageElements = getState().webpages[selectedPage].elements
  const pageChangeHistory = getState().pageChangeHistory
  const changes = [...pageChangeHistory.changes, pageChangeHistory.redo[pageChangeHistory.redo.length - 1]]

  dispatch({
    type: UPDATE_ELEMENTS_FROM_CHANGES,
    changes,
    webpageElements
  })

  dispatch({
    type: REDO_PAGE_CHANGE_HISTORY,
  })
}


export const resetPageHistoryChanges = () => dispatch => {
    dispatch({
      type: CLEAR_CHANGES_PAGE_CHANGE_HISTORY,
    })
}
