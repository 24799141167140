import React, { useState, useEffect} from 'react';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Element from './../../../Element'

import * as defaultCss from './../../../defaultCss'
import Fade from '@material-ui/core/Fade';

import Hidden from '@material-ui/core/Hidden';

import classNames from 'classnames'

import parseStringToHtml from './../../../../helpers/parseStringToHtml'
import editWrapper from './../../../default/wrappers/editWrapper'



const styles = theme => ({

  ...defaultCss.styles(theme),
  root: {
    position: "relative",
    width: "100%",
    height: "calc(100vh - 200px)", // 200px is NAVIGATION HEIGHT
    minHeight: "675px",
    background: "white"
  },
  column:{
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems: "center",

  },
  row:{
    display:"flex",
    flexDirection:"row",
    justifyContent:"center",
    alignItems: "center",
  },
  title:{
    position: "relative",
    padding: theme.spacing.unit * 1,
    marginLeft: 50,
    fontSize: 70,
    fontWeight: 400,
    color: theme.palette.primary.text,

    transition: "all .35s ease-in-out",
  },
  content:{
    position: "relative",
    width: "100%",
    height: "100%"
  },
  overlay:{
    position: "absolute",
    left: 0,
    top:0,
    width: "100%",
    height: "100%",
    background: `white`,
  },
  paper:{
    marginBottom: 70,
    position: "relative",

    height: "75%",
    width: "100%",

    marginLeft: 24,

  }

});



function Content(props) {
  const {
    classes,
    title,
    children,
    fileUrl,
    style,
    edit,
    selectedPage,
    conetetTitle,
    buttonLabel,
    carouselImages,
    theme
  } = props;

  const [ showContent, setShowContent ] = useState(false)

  useEffect(()=>{
    setTimeout(()=>setShowContent(true), 1000)
  })

  let carouselId = children.find(child => child.includes("carousel_element"))

  let childrenWithoutCarousel = carouselId ? children.filter((child, i) => i !== children.indexOf(carouselId)) : children

  return (
    <div className={classes.root}>

      <Grid container justify="space-around" style={{position: "relative",height: "100%"}}>
        {
          carouselId &&
          <Hidden smDown>
            <Grid xs={12} sm={12} md={4} lg={4} xl={4} item style={{alignItems: "center",height: "100%", width: "100%", boxShadow: "inset -5px -5px 10px -7px rgba(0,0,0,0.75)"}}>

                <Element edit={edit} selectedPage={selectedPage} elementId={carouselId}/>

            </Grid>
          </Hidden>
        }

        <Grid xs={12} sm={12} md={8} lg={8} xl={8} item className={classes.column} >
          {
            title && (
              <Fade in={true} timeout={1000} mountOnEnter unmountOnExit>
                <Typography style={{paddingTop: 24}} className={classes.paragraphTitle}>{parseStringToHtml(title)}</Typography>
              </Fade>
            )
          }

            <Grid
              container
              style={style ?  style.reduce((obj, tmp)=> {return {...obj, [tmp.name]: tmp.value}},{}) : null}
              justify="center"
              className={classNames(classes.container)}
            >

              {childrenWithoutCarousel && childrenWithoutCarousel.map((child, i)=> {
                return (

                    <Element key={child} edit={edit} selectedPage={selectedPage} elementId={child}/>

                )
              })}
            </Grid>
        </Grid>


      </Grid>


    </div>
  );
}

Content.propTypes = {
  classes: PropTypes.object.isRequired,
};



export default editWrapper(withStyles(styles, {withTheme: true})(Content))

/*
<Carousel disableButtons containerWidth={4} backgroundImage={fileUrl}>

  {carouselImages.sort((a , b) =>  Number(a.position) < Number(b.positon) ? 1 : Number(a.position) > Number(b.positon) ? -1 : 0).map((obj, i)=>{
    return(
        <Box position="relative" width="100%" height="100%">
          {obj.largeImgUrl &&
            <Box position="absolute" top="0" width="100%" height="100%">

              <img key={i} alt="img" width="100%" height="100%" src={obj.largeImgUrl}/>

            </Box>
          }

          <Box
            position="relative"
            width="100%"
            height="100%"
            maxHeight="100%"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="space-around"
            style={obj.contentStyle ?  obj.contentStyle.reduce((obj, tmp)=> {return {...obj, [tmp.name]: tmp.value}},{}) : null}
          >
            {
              obj.contentActive &&
                <React.Fragment>

                  <Typography variant="h3" style={obj.contentTitleStyle ?  obj.contentTitleStyle.reduce((obj, tmp)=> {return {...obj, [tmp.name]: tmp.value}},{}) : null}>
                    {parseStringToHtml(obj.contentTitle, {c:{color: "white", background: theme.palette.secondary.main, paddingLeft: 24, paddingRight: 24, marginTop: 8}})}
                  </Typography>

                  <Typography style={obj.contentTextStyle ?  obj.contentTextStyle.reduce((obj, tmp)=> {return {...obj, [tmp.name]: tmp.value}},{}) : null}>
                    {parseStringToHtml(obj.contentText, {c:{color: "white", background: theme.palette.primary.main, padding: 8}})}
                  </Typography>
                </React.Fragment>
            }

            {obj.buttonActive &&
              <Button
                style={obj.buttonStyle ?  obj.buttonStyle.reduce((obj, tmp)=> {return {...obj, [tmp.name]: tmp.value}},{}) : null}
                variant="contained"
                color="primary"
              >
                {parseStringToHtml(obj.buttonLabel)}
              </Button>
            }
          </Box>
        </Box>


    )
  })}

</Carousel>
*/
