import React from 'react';
import DotIcon from '@material-ui/icons/TripOrigin'

const parseStringToHtml = (string, style)=>{
  const {color, background} = style ? style : {}
  string = string.split(/(<\/?[a-z]>)/g)
  let parsedString = []


  for(let i = 0; i < string.length; i++){

    if(/(<[a-z]>)/.test(string[i])){
      let tag = string[i].replace(/\W/g, '')
      let tmp = ""
      i++

      let matcher = new RegExp(`(</${tag}>)`, 'g');

      while(!matcher.test(string[i] || i < string.length-1)){
        tmp += string[i]
        i++
      }

      switch(tag){
        case "t":
          parsedString.push(<span style={{color: "#104076", fontSize: 24, fontWeight: 800, textDecoration: "underline"}} key={i}>{tmp}<br/></span>)
          break;
        case "n":
          parsedString.push(<React.Fragment key={i}><br/>{tmp}</React.Fragment>)
          break;
        case "u":
          parsedString.push(<u key={i}>{tmp}</u>)
          break;
        case "l":

          parsedString.push(
            <span key={i} style={{display: "flex" , flexDirection:"row", paddingTop:24}}>
              <span style={{lineHeight: "-10px"}}>
                <DotIcon style={{color:"#104076", paddingRight: 8}}  />
              </span>
              <span>{parseStringToHtml(tmp)}</span>
            </span>)
          break;
        case "b":
          parsedString.push(<b style={style && style.b ? style.b : {color: "#104076"}} key={i}>{tmp}</b>)
          break;
        case "c":
          parsedString.push(
            <span style={style && style.c ? style.c : {color: "#104076", background: background ? background : "initial", padding: 8}} key={i}>
              <b>{tmp}</b>
            </span>
          )
          break;
        default:
          parsedString.push(<b style={{color: color ? color:"#104076"}} key={i}>{tmp}</b>)
          break;
      }
    }else{
      parsedString = [...parsedString, (<span key={i}>{string[i]}</span>)]
    }
  }

  return parsedString
}

export default parseStringToHtml
