import React from 'react';
import { withRouter } from 'react-router'

import Button from '@material-ui/core/Button';


import Typography from '@material-ui/core/Typography';


import Grow from '@material-ui/core/Grow';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import Collapse from '@material-ui/core/Collapse';

import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';

import * as defaultCss  from './../../../defaultCss'
import { withStyles } from '@material-ui/core/styles';

import Element from './../../../Element'

import editWrapper from './../../../default/wrappers/editWrapper'


const styles = theme => ({
  ...defaultCss.styles(theme),
  root: {
    height: "100%"
  },
  column:{
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems: "center",

  },
  row:{
    display:"flex",
    flexDirection:"row",
    justifyContent:"center",

  },


  navButtonText:{
    color: "white",
    fontWeight: 300,
    fontSize: 18
  },

  //// MENU STYLES////
  menu:{
    width:"100%",
    borderRadius: 0,
    background: theme.palette.background.secondary,

  },

  dropdownMenu:{
    display:"flex",
    flexDirection: "column",
    minWidth: 150
  },

  menuButtonDropDown:{
    color: theme.palette.primary.text,
    minWidth: 150,

    padding: theme.spacing.unit * 2,
    textAlign: "left",
    borderRadius: 0,
    width: "100%"

  },
  menuButtonLabel:{


    display:"flex",

  },

});

class NavigationDropdown extends React.Component{
  state = {
    open: false,

  };

  handleToggle = () => {
    this.setState(state => ({ open: !state.open }));
  };

  handleOpen = () =>{
    console.log(this.props)
    if(this.state.open && this.props.path) this.props.history.push(this.props.path.replace(/_/g, "/")) 
    this.setState(state => ({ open: true }));
  }

  handleClose = event => {

    this.setState({ open: false });
  };

  render() {
    const { classes, variant, children, style, drawer} = this.props;
    const { open } = this.state;

    return (
      <span className={classes.root}  onMouseLeave={this.handleClose} style={{width: drawer ? "100%": "inherit"}}>

        <Button
          onClick={this.handleOpen}
          fullWidth={variant === "menu"}
          buttonRef={node => {
            this.anchorEl = node;
          }}
          aria-owns={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onMouseEnter={this.handleOpen}
          variant="text"
          className={classes[this.props.classNames]}
          style={{width: drawer ? "100%": "inherit"}}
        >
            <Typography
              style={style ?  {...style.reduce((obj, tmp)=> {return {...obj, [tmp.name]: tmp.value}},{}), background: "none"} : {background: "none"}}
              className={classes[this.props.classNames]}

            >
              {this.props.label}
            </Typography>

        </Button>
        {
          drawer && (

            <Collapse in={open}>

                <div className={classes.column} style={{width: "100%"}}>
                  {
                    children.map((child)=>{
                      return <Element edit={this.props.edit} selectedPage={this.props.selectedPage} key={child} {...child} elementId={child} />
                    })
                  }
                </div>


            </Collapse>
          )
        }

        {
          !drawer && children && (
            <Popper  onMouseLeave={this.handleClose} onClick={this.handleClose} open={open} anchorEl={this.anchorEl} placement={variant === "menu" ? "right-start":"bottom"} transition disablePortal>
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  id="menu-list-grow"
                  style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'left top' }}
                >
                  <Paper className={classes.menu}>
                    <ClickAwayListener onClickAway={this.handleClose}>
                      <div className={classes.dropdownMenu}>
                        {
                          children.map((child)=>{

                            return <Element edit={this.props.edit} selectedPage={this.props.selectedPage} key={child} {...child} elementId={child} />
                          })
                        }
                      </div>

                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          )
        }

      </span>

    );
  }
}

export default withRouter(withStyles(styles)(editWrapper(NavigationDropdown)))
