import React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import blue from '@material-ui/core/colors/blue'
import CssBaseline from '@material-ui/core/CssBaseline';

// A theme with custom primary and secondary color.
// It's optional.
const theme = createMuiTheme({

  palette: {

    background:{
      main: "rgba(245, 245, 245, 0.99)",
      secondary: "#104076",
      transparent: "rgba(245, 245, 245, 0.9)",
      white:"rgba(255,255,255,0.97)"
    },
    primary: {
      text: "#104076",
      light: "#104076",
      main: "#104076",
      dark: "#04152f",
    },
    secondary: {
      text: "#00adef",
      light: "#00adef",
      main: "#00adef",
      dark: "#00769A",
    },
  },
  typography: {
    fontSize: 18,
    useNextVariants: true,
    fontFamily: "Roboto",
    fontWeight: 100
  },

  overrides: {
    MuiButton: {
      contained: {
        fontSize: "1.1rem",
        fontWeight: 200,
        textTransform: "initial",
        borderRadius: 0,
        background: blue[900],
        minWidth: 80,
        paddingLeft: 24,
        paddingRight: 24,
      },
      outlined: {

        fontSize: "1.1rem",
        fontWeight: 200,
        textTransform: "initial",
        borderRadius: 0,
        minWidth: 80
      },
      text: {

        fontSize: "1.1rem",
        fontWeight: 200,
        textTransform: "initial",
        borderRadius: 0,
        minWidth: 80,
        paddingLeft: 24,
        paddingRight: 24,
      },

    },
    MuiTypography: {
      h3: {
        padding: 24,
        fontWeight: 200,
      },
      h5: {
        padding: 24,
        fontWeight: 200,
      }
    }
  }
});

function withRoot(Component) {
  function WithRoot(props) {
    // MuiThemeProvider makes the theme available down the React tree
    // thanks to React context.
    return (
      <MuiThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <Component {...props} />
      </MuiThemeProvider>
    );
  }

  return WithRoot;
}

export default withRoot;
