import { GLOBAL_ELEMENTS_LOADED } from "./../../actions/types";

export default (state = false, action) => {
  switch (action.type) {
    case GLOBAL_ELEMENTS_LOADED:
      return true;
    default:
      return state;
  }
};
