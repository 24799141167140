import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const CustomInputComponent = (props) =>{
  const {
    label,
    field, // { name, value, onChange, onBlur }
    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  } = props

  const handleChange = (e)=>{
    e.target.value = !field.value

    field.onChange(e)
  }

  return(
    <FormControl error={(errors[field.name] && touched[field.name]) ? true : false} fullWidth={props.fullWidth ? props.fullWidth : true}>
      

      <FormControlLabel
        labelPlacement={"end"}
        control={
          <Checkbox
            checked={field.value}
            value={String(field.value)}
            id={field.name}
            name={field.name}
            onChange={handleChange} {...props}
          />
        }

        label={label}
      />

      {errors[field.name] &&
        touched[field.name] && <FormHelperText id="component-error-text">{errors[field.name]}</FormHelperText>}
    </FormControl>

  );
}

export default CustomInputComponent
