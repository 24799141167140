import { SET_ACTIVE_LANGUAGE } from "./../../actions/types";

export default (state = null, action) => {
  switch (action.type) {
    case SET_ACTIVE_LANGUAGE:
      return action.payload;
    default:
      return state;
  }
};
