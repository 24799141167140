import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router'

import * as defaultCss from './webElements/defaultCss'

import classNames from 'classnames'
import { useCookies } from 'react-cookie';

import { analytics } from './config/firebase'

const styles = theme => ({

  ...defaultCss.styles(theme),
  root: {
    position: "fixed",
    zIndex: 5000,
    bottom: 0,
    height: 70,
    width: "100%",
    background: theme.palette.primary.main,
    color: "white"
  },
  row:{
    display:"flex",
    flexDirection:"row",
    justifyContent:"space-around",
    width: "100%",
  },
  text:{
    fontSize: 20,
    fontWeight: 200,
    lineHeight: 2.2
  },
  button:{
    borderRadius: 0,
    fontSize: 20,
    fontWeight: 200,
    textTransform: "initial"
  }

});

function CookieBar(props) {
  const { classes, location } = props;
  const [cookies, setCookie] = useCookies(['user_allow_cookies_consent'])

  /// INITIALIZE GA ON PAGE LOAD
  React.useEffect(()=>{
    if(cookies['user_allow_cookies_consent']){
      analytics.setAnalyticsCollectionEnabled(true)
    }else{
      analytics.setAnalyticsCollectionEnabled(false)
    }
  },[])
  // SEND PAGE VIEW ON EVERY LOCATION CHANGE
  React.useEffect(()=>{
    if(cookies['user_allow_cookies_consent']){
      analytics.logEvent('pageview', {page: window.location.pathname + window.location.search})
    }
  },[location])

  const allowCookies = ()=>{
    setCookie('user_allow_cookies_consent', true, {maxAge: 315569260})
    window.location.reload()
  }

  const goTo = (path)=>{
    props.history.push(path)
  }

  if(!cookies['user_allow_cookies_consent']){
    return (
      <div  className={classNames(classes.root, classes.column)}>

      <div className={classNames(classes.row)}>
        <Typography className={classes.text} color="inherit">Z obiskom in uporabo spletnega mesta soglašate z uporabo in beleženjem piškotkov.</Typography>
        <div>
          <Button onClick={allowCookies} className={classes.button} style={{fontWeight: 400}} variant="contained">V redu</Button>
          <Button onClick={()=>goTo('/pogojiuporabe?scroll=text_element_jypkuf1q')} className={classes.button} variant="text" style={{color:"white", paddingLeft: 24}}>Vec o piškotnih</Button>
        </div>
      </div>

      </div>
    );
  }else return null

}

CookieBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(CookieBar))
