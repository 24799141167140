
import * as firebase from './../../../../config/firebase'

import {
  FETCH_ALL_SUBSCRIPTIONS
} from './../../types'

export const fetchAllSubscribersOnce = () => async dispatch => {
    console.log("fetchAllSubscribersOnce")
    let data = await firebase.subscriptions.orderByKey().limitToFirst(10).once("value")

    dispatch({
      type: FETCH_ALL_SUBSCRIPTIONS,
      payload: data.val()
    })
}

export const addSubscribedUser = (email, type)=> async dispatch => {

  firebase.subscriptions.push().set({
    email,
    type,
    datetime: new Date().getTime()
  }).then(function() {

  }).catch(function(error) {
    console.log(error)
  });
}
