import * as tehnofanCss from './custom/tehnofan/tehnofanCss'

export const styles = (theme) => {
  return {

    row:{
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",

      flexWrap: "wrap"
    },
    column:{
      position: "relative",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",

    },

    relative: {
      position: "relative",
    },

    shadows:{
      boxShadow: theme.shadows[3]
    },


    ///// PADDING /////

    padding1:{
      padding: theme.spacing.unit * 1,
    },

    paddingTop:{
      paddingTop: theme.spacing.unit * 3,
    },
    paddingBottom:{

    },
    paddingLeft:{

    },
    paddingRight:{

    },

    /// TEHNOFAN ///

    ...tehnofanCss.styles(theme)
  }
}


 export const allClasses = [
   ...tehnofanCss.allClasses,
   "row", "column", "padding1", "shadows", "relative"
 ]
