import React from 'react';
import { withRouter } from 'react-router'

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';

import * as defaultCss  from './../../defaultCss'

import classNames from 'classnames'

import translateElement from './../wrappers/translateElement'
import editWrapper from './../wrappers/editWrapper'
import animationWrapper from './../wrappers/animationWrapper'

import parseStringToHtml from './../../../helpers/parseStringToHtml'

const styles = theme => ({
  ...defaultCss.styles(theme),
  root:{

  },
  active:{
    border: "2px solid red",
  }
});


class TextWrapper extends React.Component {

  state = {

  }

  render() {
    const {
      classes,
      handleClick,
      selectedElement,
      style,
      variant,
      align,
      content,
      elementId,

    } = this.props

    let activeStyle = selectedElement === elementId ? this.props.activeStyle : {}

    

    return (
      <Fade in={true} timeout={1500}>
        <Typography
          id={elementId}
          onClick={handleClick}
          className={classNames(classes.root, classes[this.props.classNames])}
          variant={variant}
          align={align}
          style={style && Array.isArray(style) ?  style.reduce((obj, tmp)=> {return {...obj, [tmp.name]: tmp.value}},{}) : style ? style : {}}
        >
          {variant === "body1" ? parseStringToHtml(content): content}
        </Typography>
      </Fade>

    )
  }
}

TextWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
};


export default withRouter(withStyles(styles)(translateElement(editWrapper(animationWrapper(TextWrapper)))))
