import React, { useState } from 'react';

import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';


import classNames from 'classnames'

const ROOT_HEIGHT = 500

const styles = theme => ({
  root: {

    position: 'relative',


    borderBottom: "3px solid "+ theme.palette.primary.main,
    height: ROOT_HEIGHT,
    overflow: "hidden"
  },
  column:{
    position: "relative",
    height:350,

    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center",
  },
  shadow:{
    position: "relative",
    zIndex: 20,
    boxShadow: theme.shadows[3]
  },
  sliderContainer:{
    height: ROOT_HEIGHT,
    position:"absolute",
    left: 0,
    top: 0,
    display:"flex",
    flexDirection:"row"
  },
  elementWrapper:{
    width:"100vw",
    height:"100%",
    position:"relative",
  },
  element:{
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,


  },

  firstPageElement:{
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,

  },
  buttonLeftWrapper:{
    position: "absolute",
    top: 0,
    bottom: 0,
    left:0,

    display: "flex",
    flexDirection:"column",
    justifyContent:"center"
  },
  buttonLeft:{
    borderRadius: 0,
    background: "rgba(255,255,255,0.8)",
    zIndex:502,
  },
  buttonRightWrapper:{
    position: "absolute",
    top: 0,
    bottom: 0,
    right:0,

    display: "flex",
    flexDirection:"column",
    justifyContent:"center"
  },
  buttonRight:{
    borderRadius: 0,
    background: "rgba(255,255,255,0.8)",
    zIndex:502,
  },
  icon:{
    fontSize: 60,
    color: theme.palette.primary.main
  },
  overlay:{
    position: "absolute",
    top: 0,
    left: 0,

    width: "100%",
    height: "100%",
    background: "rgba(255,255,255,0.65)",
    zIndex: 500,
  },


  imageOverlay:{
    position:"absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",

  },
  galleryItemInfo:{
    position:"absolute",
    bottom: 0,
    right: 0,
    width: "100%",
    background: theme.palette.background.transparent,
    height: "20%",
    boxShadow: theme.shadows[5],

    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center",
    transition: "all .35s ease-in-out"
  },
  galleryItemInfoLarge:{
    height: "100%",
    transition: "all .35s ease-in-out",

    background: theme.palette.background.transparent,
  },
  imageTitle: {

    fontSize: 20,
    fontWeight: 600,
    textTransform: "uppercase",
    borderBottom: "2px solid rgba(0,0,0,0)",


  },
  imageTitleActive:{
    fontSize: 32,
    fontWeight: 600,
    textTransform: "uppercase",
    borderBottom: "2px solid "+ theme.palette.secondary.main,

    [theme.breakpoints.down("lg")]:{
      fontSize: 20,
    }
  },
  imageElementButtonWrapper:{
    position: "absolute",
    left: 0,
    right: 0,
    bottom: theme.spacing.unit * 4,

  }


});

const CarouselImageButton = (props)=>{
  const { classes, elements, open, activeElement, width, slideDirection, hovered, position, label, image, title, defaultState, fileUrl  } = props;
  const [active, setActive] = useState(defaultState ? defaultState : false);

  let elementWidth = (width )/ 6
  let delay = 500

  let rootStyle={
    width: "100%",
    height: ROOT_HEIGHT,
    overflow:"hidden",

  }

  return(
    <div style={rootStyle} onMouseEnter={()=>setActive(true)} onMouseLeave={()=>setActive(false)} className={position % 2 !== 0 ? classNames(classes.firstPageElement, classes.shadow) : classes.firstPageElement} >
      <img  height="100%" width="auto" src={fileUrl} alt="element" />
      <div className={active ? classNames(classes.galleryItemInfo, classes.galleryItemInfoLarge): classes.galleryItemInfo}>
        <div className={classes.column}>
          {active && (
            <Typography className={classes.imageTitle}>REŠITVE ZA</Typography>
          )}
          <Typography  className={active ? classes.imageTitleActive:classes.imageTitle} >{active ? title : label}</Typography>
        </div>

        <Fade in={active} timeout={500}>
          <div className={classes.imageElementButtonWrapper}>
            <Button  variant="outlined" color="primary" style={{borderRadius: 0, fontSize: 20, fontWeight: 600}}>Več o filtrih</Button>
          </div>
        </Fade>

      </div>
    </div>
  )
}


export default withStyles(styles)(CarouselImageButton)
