
import {
  ADD_CHANGE_PAGE_CHANGE_HISTORY,
  UNDO_PAGE_CHANGE_HISTORY,
  REDO_PAGE_CHANGE_HISTORY,
  CLEAR_CHANGES_PAGE_CHANGE_HISTORY
} from "./../../actions/types";


export default (state = {changes:[], redo:[]}, action) => {
  let changes = [...state.changes]
  let redo = [...state.redo]

  switch (action.type) {
    case ADD_CHANGE_PAGE_CHANGE_HISTORY:
      changes.push(action.payload)
      return {changes, redo};
    case UNDO_PAGE_CHANGE_HISTORY:

      //delete first change from changes array and move first change to redu array
      let lastChange = changes.pop()
      redo.push(lastChange)

      return {changes, redo};
    case REDO_PAGE_CHANGE_HISTORY:
      //delete first redu from redu array and move first redu to changes array
      let lastRedo = redo.pop()
      changes.push(lastRedo)

      return {changes, redo};
    case CLEAR_CHANGES_PAGE_CHANGE_HISTORY:
      return {changes:[], redo:[]}
    default:
      return state;
  }
};
