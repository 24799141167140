
/// LOGIN USER DATA ///
export const SET_AUTH = "SET_AUTH"

/// PAGES INFO CRUD ///
export const FETCH_PAGES_INFO = "FETCH_PAGES_INFO"
export const ADD_PAGE_INFO = "ADD_PAGE_INFO"
/// PAGES CRUD ///
export const FETCH_ALL_PAGES = "FETCH_ALL_PAGES"
export const FETCH_PAGE = "FETCH_PAGE"
export const ADD_NEW_PAGE = "ADD_NEW_PAGE"
export const DELETE_PAGE = "DELETE_PAGE"
export const UPDATE_PAGE = "UPDATE_PAGE"

/// ELEMENTS CRUD ///
export const FETCH_PAGE_ELEMENTS = "FETCH_PAGE_ELEMENTS"
export const ADD_PAGE_ELEMENT = "ADD_PAGE_ELEMENT"
export const SET_ROOT_ELEMENT = "SET_ROOT_ELEMENT"
export const ADD_ROOT_PAGE_ELEMENT = "ADD_ROOT_PAGE_ELEMENT"
export const EDIT_PAGE_ELEMENT = "EDIT_PAGE_ELEMENT"
export const EDIT_PAGE_ELEMENT_CONTENT = "EDIT_PAGE_ELEMENT_CONTENT"
export const EDIT_PAGE_ELEMENT_CHILDREN = "EDIT_PAGE_ELEMENT_CHILDREN"

export const FETCH_GLOBAL_ELEMENTS = "FETCH_GLOBAL_ELEMENTS"

export const DELETE_PAGE_ELEMENT = "DELETE_PAGE_ELEMENT"
export const UPDATE_ELEMENTS_FROM_CHANGES = "UPDATE_ELEMENTS_FROM_CHANGES"



/// SUBSCRIPTIONS CRUD //
export const FETCH_ALL_SUBSCRIPTIONS = "FETCH_ALL_SUBSCRIPTIONS"

///  USERS CRUD ////
export const FETCH_USERS_BY_MAIL = "FETCH_USERS_BY_MAIL"
export const FETCH_USERS = "FETCH_USERS"
export const UPDATE_USER = "UPDATE_USER"
export const SET_AUTH_ACCESS_TOKEN = "SET_AUTH_ACCESS_TOKEN"


/// WEBPAGE VARIABLES ///
export const SET_SELECTED_ELEMENT = "SET_SELECTED_ELEMENT"
export const SET_SELECTED_PAGE_ELEMENTS = "SET_SELECTED_PAGE_ELEMENTS"
export const SET_SELECTED_PAGE = "SET_SELECTED_PAGE"

export const SET_ACTIVE_MODAL = "SET_ACTIVE_MODAL"
export const SET_PREVIOUS_MODAL = "SET_PREVIOUS_MODAL"
export const CLOSE_ACTIVE_MODAL = "CLOSE_ACTIVE_MODAL"

export const SET_ACTIVE_DRAWER = "SET_ACTIVE_DRAWER"

export const SET_ACTIVE_DOCUMENT_PAGE = "SET_ACTIVE_DOCUMENT_PAGE"

export const GLOBAL_ELEMENTS_LOADED = "GLOBAL_ELEMENTS_LOADED"

/// PAGE CHANGE HISTORY ///

export const UNDO_PAGE_CHANGE_HISTORY = "UNDO_PAGE_CHANGE_HISTORY"
export const REDO_PAGE_CHANGE_HISTORY = "REDO_PAGE_CHANGE_HISTORY"
export const ADD_CHANGE_PAGE_CHANGE_HISTORY = "ADD_CHANGE_PAGE_CHANGE_HISTORY"
export const CLEAR_CHANGES_PAGE_CHANGE_HISTORY = "CLEAR_CHANGES_PAGE_CHANGE_HISTORY"


//// DICTIONARY / LANGUAGE ////////////////
export const SET_ACTIVE_LANGUAGE = "SET_ACTIVE_LANGUAGE"
export const FETCH_DICTIONARY = "FETCH_DICTIONARY"
export const ADD_DICTIONARY_LANGUAGE = "ADD_DICTIONARY_LANGUAGE"
export const FETCH_DICTIONARY_LANGUAGES = "FETCH_DICTIONARY_LANGUAGES"
export const FETCH_DICTIONARY_LANGUGAE_DATA = "FETCH_DICTIONARY_LANGUGAE_DATA"

export const DELETE_DICTIONARY_LANGUAGE = "DELETE_DICTIONARY_LANGUAGE"

export const UPDATE_ELEMENT_LANGUAGE = "UPDATE_ELEMENT_LANGUAGE"
