
import { SET_ACTIVE_DRAWER } from "./../../actions/types";


export default (state = "navigationDrawer", action) => {
  switch (action.type) {
    case SET_ACTIVE_DRAWER:
      return action.payload;
    default:
      return state;
  }
};
