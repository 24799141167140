
import {
  FETCH_PAGE_ELEMENTS,
  EDIT_PAGE_ELEMENT_CHILDREN,
  EDIT_PAGE_ELEMENT,
  EDIT_PAGE_ELEMENT_CONTENT,
  ADD_PAGE_ELEMENT,
  SET_ROOT_ELEMENT,
  DELETE_PAGE_ELEMENT,
  UPDATE_ELEMENTS_FROM_CHANGES
} from "./../../../actions/types";

import _ from 'lodash'

function customizer(objValue, srcValue) {
  if (_.isArray(objValue)) {
    return srcValue
  }
}

export default (state = "loading", action) => {
  switch (action.type) {
    case FETCH_PAGE_ELEMENTS:
      if(state === "loading")return _.cloneDeep(action.payload)
      else return {...state, ..._.cloneDeep(action.payload)}
    case ADD_PAGE_ELEMENT:
      state[action.parentId].children = [...action.parentChildren]
      state = {...state, [action.newElement.id]: action.newElement}
      return {...state}
    case SET_ROOT_ELEMENT:

      state["root"] = {...action.payload}
      return {...state}
    case EDIT_PAGE_ELEMENT:

      state[action.parentId].children = [...action.parentChildren]
      state[action.editedElement.id] = {...action.editedElement}
      return {...state}
    case EDIT_PAGE_ELEMENT_CHILDREN:
      state[action.elementId].children = action.children
      return {...state}
    case EDIT_PAGE_ELEMENT_CONTENT:
      state[action.editedElement.id] = {...action.editedElement}
      return {...state}
    case DELETE_PAGE_ELEMENT:
      state[action.parentId].children = [...action.parentChildren]
      delete state[action.elementId]
      return {...state}
    case UPDATE_ELEMENTS_FROM_CHANGES:
      let webpageElements = _.cloneDeep(action.webpageElements)

      //remove last change from array
      let destructedChanges = {}
      action.changes.forEach((change)=>{
        let destructedChange = {}

        Object.keys(change).forEach((path)=>{
          let tmp = destructedChange
          let pathArray = path.replace("/elements/","").split("/")
          pathArray.forEach( (o, index) =>{
            tmp[o] = {}

            if(pathArray.length-1 === index) tmp[o] = change[path]
            else tmp = tmp[o]
          })

        })


        destructedChanges = _.mergeWith(destructedChanges, destructedChange, customizer)
      })
      //console.log(destructedChanges)

      return {...state,..._.mergeWith(webpageElements, destructedChanges, customizer)}
    default:
      return state;
  }
};
