import {
  SET_AUTH,
  SET_AUTH_ACCESS_TOKEN
} from "./../../actions/types";

export default (state = "loading", action) => {
  switch (action.type) {
    case SET_AUTH:
      return action.payload;
    case SET_AUTH_ACCESS_TOKEN:
      state.accessToken = action.payload
      return {...state}
    default:
      return state;
  }
};
