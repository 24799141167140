import {
  SET_AUTH,
  FETCH_USERS,
  SET_AUTH_ACCESS_TOKEN,
  UPDATE_USER
} from './../../types'

import docCookies from './../../../../helpers/cookies'
import * as firebase from './../../../../config/firebase'

const fetchUserInfoOnce = async (uid) =>{
  console.log("fetchUserInfoOnce")
  try{
    let result = await firebase.userInfoRef.child(uid).once('value')
    return result.val()

  }catch(err){
    console.log(err)
  }

}

export const isAuthenticated = ()=> async (dispatch, getState) =>{

  firebase.authRef.onAuthStateChanged(async (user)=>{
    console.log("onAuthStateChanged")
    if (user) {
      //console.log(user, document)
      /*
        fetch user info once
      */
      let auth = getState().auth
      if(!auth || !auth.userInfo)user.userInfo = await fetchUserInfoOnce(user.uid)


      if(user.userInfo.admin){

        //save accessToken to auth user
        let accessToken = docCookies.getItem("gapi_access_token")

        //if accessToken cookie expired, reauthenticate

        user.accessToken = accessToken
      }


      dispatch({
        type: SET_AUTH,
        payload: {...user}
      })
    } else {
      dispatch({
        type: SET_AUTH,
        payload: null
      })
    }
  });

}

export const authenticateUser = (email, password) => async dispatch => {
  console.log("authenticateUser")
  try{
    let auth = await firebase.authRef.signInWithEmailAndPassword(email, password)

    let user = auth.user
    user.userInfo = await fetchUserInfoOnce(user.uid)

    dispatch({
      type: SET_AUTH,
      payload: {...user}
    })
    return {success: true}
  }catch(err){
    console.log(err)
    return {error: true, status: err}
  }
}


// @type ---> type of login, admin login or default login
export const reauthenticateUserWithGoogle = (type) => async  dispatch => {
  try{
    let provider =  new firebase.authProviderRef();

    if(type === "admin")provider.addScope("https://www.googleapis.com/auth/analytics.readonly");

    let result = await firebase.authRef.signInWithPopup(provider)

    let accessToken = result.credential.accessToken

    ///save accessToken to cookie ... cookie expires in 1h same as accessToken
    docCookies.setItem("gapi_access_token", accessToken, 3600)

    dispatch({
      type: SET_AUTH_ACCESS_TOKEN,
      payload: accessToken
    })
    return accessToken
  }catch(err){
    console.log(err)
  }

}

// @type ---> type of login, admin login or default login
export const authenticateUserWithGoogle = (type) => async dispatch => {
  console.log("authenticateUserWithGoogle")
  let provider =  new firebase.authProviderRef();
  if(type === "admin")provider.addScope("https://www.googleapis.com/auth/analytics.readonly");

  firebase.authRef.signInWithPopup(provider).then(async (result) => {

    let user = result.user;
    user.accessToken = result.credential.accessToken;
    ///save accessToken to cookie ... cookie expires in 1h same as accessToken
    docCookies.setItem("gapi_access_token", user.accessToken, 3600)
    /*
    user.userInfo = await fetchUserInfoOnce(user.uid)

    */

    dispatch({
      type: SET_AUTH,
      payload: {...user}
    })

    return {success: true}
    // ...
  }).catch(function(error) {

    console.log(error)
  });
}

export const authenticateUserWithGoogleRedirect = (type) => async dispatch => {
  console.log("authenticateUserWithGoogle")
  let provider =  new firebase.authProviderRef();
  if(type === "admin")provider.addScope("https://www.googleapis.com/auth/analytics.readonly");
  firebase.authRef.signInWithRedirect(provider);

  /*
    firebase.authRef.signInWithPopup(provider).then(async (result) => {

      let user = result.user;
      user.userInfo = await fetchUserInfoOnce(user.uid)

      user.accessToken = result.credential.accessToken;

      ///save accessToken to cookie ... cookie expires in 1h same as accessToken
      docCookies.setItem("gapi_access_token", user.accessToken, 3600)

      dispatch({
        type: SET_AUTH,
        payload: user
      })
      return {success: true}
      // ...
    }).catch(function(error) {


      console.log(error)
    });
  */
}

export const fetchGoogleLoginRedirectResult = () => async dispatch => {
  let result = await firebase.authRef.getRedirectResult()
  if(result.user && result.credential){

    let user = result.user
    user.accessToken = result.credential.accessToken;
    docCookies.setItem("gapi_access_token", user.accessToken, 3600)

  }

}


export const logOut = ()=> async dispatch => {
  firebase.authRef.signOut().then(function() {
    dispatch({
      type: SET_AUTH,
      payload: "loading"
    })
  }).catch(function(error) {
    // An error happened.
  });
}

export const fetchUsersOnce = () => async dispatch =>{
  console.log("fetchUsersOnce")
  firebase.usersRef.orderByKey().limitToFirst(10).once("value", snapshot => {
    console.log(snapshot.val())
    dispatch({
      type: FETCH_USERS,
      payload: snapshot.val()
    });
  });
}


export const updateUserInfo  = (uid, values)=> async dispatch =>{
  console.log(values)

  firebase.usersRef.child(uid).update({...values}).catch((err)=>{
    console.log(err)
  })

  dispatch({
    type: UPDATE_USER,
    uid,
    payload: values
  });
}
