import React from 'react';

import PropTypes from 'prop-types';

import Fade from '@material-ui/core/Fade';
import Box from '@material-ui/core/Box';
import LocationIcon from '@material-ui/icons/LocationOn'

import editWrapper from './../wrappers/editWrapper'

import GoogleMapReact from 'google-map-react';


function GoogleMapsWrapper(props){

  const {
    offset,
    latitude,
    longitude,
    zoom,
    mapKey,
    minHeight
  } = props

  const defaultProps = {
    center: {
      lat: Number(latitude),//46.029009,
      lng: Number(longitude),//14.513204
    },
    zoom: Number(zoom)//15
  }

  const AnyReactComponent = ({ text }) => <div><LocationIcon /></div>;

  console.log(props)
  return (
    <Fade in={true} timeout={1500}>
      <div style={{minHeight: minHeight ? minHeight:300, height: offset ?  `calc( 100vh - ${offset})` : '50vh', width: '100%'}}>
        <GoogleMapReact
          bootstrapURLKeys={{key: mapKey}}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
        >
          <AnyReactComponent
            lat={Number(latitude)}
            lng={Number(longitude)}
          />
        </GoogleMapReact>
      </div>
    </Fade>
  )

}

GoogleMapsWrapper.propTypes = {

};


export default editWrapper(GoogleMapsWrapper)
