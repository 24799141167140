import fbIcon from './fb.svg'
import liIcon from './li.svg'
import ytIcon from './yt.svg'
import igIcon from './ig.svg'

export default {

  /// SOCIAL
  facebookIcon: fbIcon,
  youtubeIcon: ytIcon,
  linkedInIcon: liIcon,
  instagramIcon: igIcon,

  //////

  cartIcon: "https://firebasestorage.googleapis.com/v0/b/webpagebuilder-cf99c.appspot.com/o/icons%2Fshopping-cart%20(1).svg?alt=media&token=2e7441a0-5fbf-425d-93df-ac87a2902bd3"
}
