import React from 'react';
import { withRouter } from 'react-router'
import PropTypes from 'prop-types';
import withRoot from './../withRoot';
import * as actions from './../redux/actions'
import { connect } from "react-redux";

import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Fab from '@material-ui/core/Fab';
import EditIcon from '@material-ui/icons/Edit';
import PersonIcon from '@material-ui/icons/Person';
import GridElement from './default/elements/GridElement'
import ItemElement from './default/elements/ItemElement'
import ButtonElement from './default/elements/ButtonElement'
import TextElement from './default/elements/TextElement'
import ImageElement from './default/elements/ImageElement'
import CarouselElement from './default/elements/CarouselElement'
import BoxElement from './default/elements/BoxElement'
import GoogleMapsElement from './default/elements/GoogleMapsElement'
import SearchedHitsElement from './default/elements/SearchedHitsElement'

import CustomElement from './custom/CustomElement'


const selectedElementStyle = {
  position: "relative",
  border: "1px solid rgba(144, 202, 249, .5)",
  width: "inherit",
  background: "rgba(144, 202, 249,0.1)",
  padding: 8,
  boxShadow: "0px 0px 5px 0px rgba(144, 202, 249,0.75)"
}

const styles = theme => ({

});

class Element extends React.Component {

  state = {
    selectedPage: this.props.selectedPage
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedPage !== this.props.selectedPage) this.setState({ selectedPage: this.props.selectedPage })
  }

  handleClick = (e) => {
    e.stopPropagation();
    this.props.setSelectedElement(this.props.elementId)
  }

  handleParentSelect = ()=>{
    //console.log(this.props.elements[this.props.selectedElement].parent)
    this.props.setSelectedElement(this.props.elements[this.props.selectedElement].parent)
  }



  render() {

    const { webpages, elementId, edit, elements, selectedElement, animationActive } = this.props
    const { selectedPage } = this.state

    let page = webpages ? webpages[selectedPage] : {}

    let element = elements[elementId]

    if (element) {
      let type = element.type
      let children = element.children
      switch (type) {
        case 'grid_container':
          return (
            <GridElement selectedElement={selectedElement} elementId={elementId}  edit={edit} {...element} >
              {children && children.map((childId, i)=> <Element
                id={childId}
                edit={edit}
                key={childId}
                elementId={childId}
                setSelectedElement={this.props.setSelectedElement }
                setActiveModal={this.props.setActiveModal}
                selectedPage={selectedPage}
                selectedElementStyle={selectedElementStyle}
                selectedElement={selectedElement}
                webpages={webpages}
                elements={elements}
                page={page} />)}
            </GridElement>
          )
        case 'grid_item':
          return (
            <ItemElement selectedElement={selectedElement} selectedElementStyle={selectedElementStyle} elementId={elementId} edit={edit} handleClick={edit ? this.handleClick:null}  {...element} >
                {children && children.map((childId, i)=> <Element
                  id={childId}
                  edit={edit}
                  key={childId}
                  elementId={childId}
                  setSelectedElement={this.props.setSelectedElement }
                  selectedElementStyle={selectedElementStyle}
                  setActiveModal={this.props.setActiveModal}
                  selectedElement={selectedElement}
                  selectedPage={selectedPage}
                  webpages={webpages}
                  elements={elements}
                  page={page}  />)}
              </ItemElement>
          )
        case 'box_element':
          return (
            <BoxElement selectedElement={selectedElement} selectedElementStyle={selectedElementStyle} elementId={elementId} edit={edit} animationActive={animationActive} {...element} />
          )
        case 'carousel_element':
          return (
            <CarouselElement selectedElement={selectedElement} selectedElementStyle={selectedElementStyle} elementId={elementId} edit={edit}  {...element} />
          )
        case 'button_element':
          return (
            <ButtonElement elementId={elementId} selectedElement={selectedElement}  edit={edit} animationActive={animationActive}  {...element} />
          )
        case 'text_element':

          return(
            <TextElement elementId={elementId} selectedElement={selectedElement} edit={edit} animationActive={animationActive} {...element} />
          )
        case 'image_element':
          return(
            <ImageElement elementId={elementId} selectedElement={selectedElement} edit={edit} {...element} />
          )
        case 'searched_hits_element':
          return(
            <SearchedHitsElement elementId={elementId} selectedElement={selectedElement} edit={edit} {...element} />
          )
        case 'google_maps_element':
          
          return <GoogleMapsElement elementId={elementId} selectedElement={selectedElement} edit={edit} {...element} />

        default:
          if (type.split("_").includes("custom")) return <CustomElement elementId={elementId} selectedElement={selectedElement} selectedPage={selectedPage} edit={edit} {...this.props} />
          else return null
      }
    } else return null
  }
}

Element.propTypes = {
  selectedPage: PropTypes.string.isRequired,
  edit: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ webpages, elements, selectedElement }) => {
  return {
    webpages,
    elements,
    selectedElement
  }
}

export default withRouter(
                    withRoot(
                      connect(mapStateToProps, actions)(
                        withStyles(styles)(Element))))
