
import {
  FETCH_USERS,
  FETCH_USERS_BY_MAIL,
  UPDATE_USER
} from "./../../../actions/types";


export default (state = "loading", action) => {
  switch (action.type) {
    case FETCH_USERS:
      return action.payload;
    case FETCH_USERS_BY_MAIL:
      if(state !== "loading") return {...state, ...action.payload}
      else return action.payload;
    case UPDATE_USER:
      state[action.uid] = {...state[action.uid], ...action.payload}
      return state;
    default:
      return state;
  }
};
