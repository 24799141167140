import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import * as tehnofanCss from './../tehnofanCss'
import * as defaultCss from './../../../defaultCss'

import classNames from 'classnames'
import GoogleMapReact from 'google-map-react';
import LocationIcon from '@material-ui/icons/LocationOn'

import editWrapper from './../../../default/wrappers/editWrapper'

const styles = theme => ({
  ...tehnofanCss.styles(theme),
  ...defaultCss.styles(theme),
  root: {
    position: "relative",
    width: "100%",
  },
  row:{
    width: "100%",
    display:"flex",
    flexDirection:"row",
    justifyContent:"space-between",
    alignItems: "center",

  },
  column:{
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems: "center",

  },
  titleContainer:{

    padding: theme.spacing.unit *3
  },
  container:{
    height: "100%",
    position: "relative"
  },
  content:{
    position: "relative"
  },
  text:{

    fontSize: 12,
    fontWeight: 300,

    [theme.breakpoints.down('xs')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 20,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 20,
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: 20,
    },
  },

  title:{

    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.primary.main,
    [theme.breakpoints.down('xs')]: {
      fontSize: 15,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 22,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 24,
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: 24,
    },
  }

});

function GoogleMapContainer(props){

  const defaultProps = {
    center: {
      lat: 46.029009,
      lng: 14.513204
    },
    zoom: 15
  };

  const AnyReactComponent = ({ text }) => <div><LocationIcon /></div>;
  const {offset} = props
  return (
    // Important! Always set the container height explicitly
    <div style={{minHeight: 300, height: offset ?  `calc( 100vh - ${offset})` : '100vh', width: '100%', padding: 24 }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyClvmdFEpa9Z7XC57ydiPGB5tFfq5UKk8o" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        <AnyReactComponent
          lat={46.029009}
          lng={14.513204}

        />
      </GoogleMapReact>
    </div>
  );

}



function Contacts(props) {
  const { classes, title, children, fileUrl, style, edit, selectedPage, contentPosition, backgroundImagePosition } = props;

  let backgroundImageRight = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background:  "linear-gradient(to bottom, #FFFFFF 10%, rgba(255, 255, 255, 0.3) 25%, rgba(255, 255, 255, 0) 50%),"+
                 "linear-gradient(to top, #FFFFFF 0%, rgba(255, 255, 255, 0.3) 25%, rgba(255, 255, 255, 0) 50%),"+
                 "linear-gradient(to left, #FFFFFF 0%,  rgba(255, 255, 255, 0) 25%),"+
                 `linear-gradient(to right, #FFFFFF 50%, rgba(255, 255, 255, 0) 85%), url(${fileUrl})`,

    backgroundRepeat: "no-repeat",
    backgroundSize: "auto 100%",
    backgroundPosition: "right top"
  }

  let backgroundImageLeft = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background:  "linear-gradient(to bottom, #FFFFFF 0%, rgba(255, 255, 255, 0.3) 25%, rgba(255, 255, 255, 0) 50%),"+
                 "linear-gradient(to top, #FFFFFF 0%, rgba(255, 255, 255, 0.3) 25%, rgba(255, 255, 255, 0) 50%),"+
                 "linear-gradient(to right, #FFFFFF 0%,  rgba(255, 255, 255, 0) 25%),"+
                 `linear-gradient(to left, #FFFFFF 60%, rgba(255, 255, 255, 0) 100%), url(${fileUrl})`,

    backgroundRepeat: "no-repeat",
    backgroundSize: "auto 100%",

  }



  return (
    <div style={style ?  style.reduce((obj, tmp)=> {return {...obj, [tmp.name]: tmp.value}},{}) : null}  className={classes.root}>
      <span style={backgroundImageLeft}></span>
      <Grid container justify="center"  className={classes.content}>
        <Grid item sm={12} >
          <div className={classes.titleContainer}>
            <Typography className={classes.paragraphTitle}>{title}</Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} className={classNames(classes.paper)}>
          <div className={classes.column} style={{height:"100%", alignItems: "center"}}>
            <Grid container justify="center">

              <Grid item xs={12} sm={12} md={4} lg={3}>
                <div className={classes.column} style={{height: "100%",justifyContent: "space-between", padding: 24}}>
                  <div className={classes.column} style={{width: "100%", alignItems: "flex-start", justifyContent:"flex-start"}}>
                    <Typography className={classes.title}>Tehnofan d.o.o.</Typography>
                    <Typography className={classes.text}>Ilovški Štradon 41</Typography>
                    <Typography className={classes.text}>1000 Ljubljana, Slovenija</Typography>
                    <Typography className={classes.text}>www.tehnofan.si</Typography>
                  </div>

                  <div className={classes.row}>
                    <Typography className={classes.title}>Telefon</Typography>
                    <Typography className={classes.text}>(01) 4274 476</Typography>
                  </div>
                  <div className={classes.row}>
                    <Typography className={classes.title}>Mobitel</Typography>
                    <Typography className={classes.text}>(041) 687 090</Typography>
                  </div>
                  <div className={classes.row}>
                    <Typography className={classes.title}>Email</Typography>
                    <Typography className={classes.text}>info@tehnofan.si</Typography>
                  </div>
                </div>

              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={5}>
                <GoogleMapContainer offset={"500px"}/>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

Contacts.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(editWrapper(Contacts))
