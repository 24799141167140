import React from 'react';
import { withRouter } from 'react-router'

import * as actions from './../../../redux/actions'
import { connect } from "react-redux";

import classNames from 'classnames'


import { withStyles } from '@material-ui/core/styles';


import Navigation from './elements/Navigation'
import NavigationDropdown from './elements/NavigationDropdown'
import Carousel from './elements/Carousel'
import CarouselImageButton from './elements/CarouselImageButton'
import CircleImageButton from './elements/CircleImageButton'
import ImageButton from './elements/ImageButton'
import Footer from './elements/Footer'
import Content from './elements/Content'
import HomeContent from './elements/HomeContent'
import WaterArticles from './elements/WaterArticles'
import Contacts from './elements/Contacts'
import Tldr from './elements/Tldr'
import Subscription from './elements/Subscription'

const styles = theme => ({
  selectedElementContainer:{
    position:"relative"
  }
});

class TehnofanElement extends React.Component {



  render() {

    const {handleClick, classes,selectedPage, elementId, edit, elements, drawer, editWrapper, selectedElementStyle, selectedElement } = this.props

    let element = elements[elementId]
    let type = element && element.type

  
    if(type === "custom_element_tehnofan_navigation"){
      return(
        <Navigation edit={edit} selectedPage={selectedPage} {...element} />
      )
    }else if(type === "custom_element_tehnofan_navigation_dropdown"){
      return(
        <NavigationDropdown edit={edit} selectedPage={selectedPage} {...element}  drawer={drawer}/>
      )

    }else if(type === "custom_element_tehnofan_carousel"){
      return(
        <Carousel edit={edit} selectedPage={selectedPage} {...element} />
      )

    }else if(type === "custom_element_tehnofan_carousel_image_button"){
      return(
        <CarouselImageButton edit={edit} selectedPage={selectedPage} {...element} />
      )

    }else if(type === "custom_element_tehnofan_circle_image_button"){

      return (
          <CircleImageButton
             edit={edit}
             selectedPage={selectedPage}
             handleClick={handleClick}
             onClick={this.props.handleClick}
             className={classNames(classes.root)}
             selectedElementStyle={selectedElementStyle}
             {...element}
         />
      )
    }else if(type === "custom_element_tehnofan_image_button"){
      return(
        <ImageButton edit={edit} selectedPage={selectedPage} {...element} />
      )

    }else if(type === "custom_element_tehnofan_content"){
      return(
        <Content  edit={edit} selectedPage={selectedPage}  {...element} />
      )

    }else if(type === "custom_element_tehnofan_home_content"){
      return(
        <HomeContent edit={edit} selectedPage={selectedPage}  {...element} />
      )

    }else if(type === "custom_element_tehnofan_footer"){
      return(
        <Footer  edit={edit} selectedPage={selectedPage}  {...element} />
      )

    }else if(type === "custom_element_tehnofan_water_articles"){
      return(
        <WaterArticles  edit={edit} selectedPage={selectedPage}  {...element}  />
      )

    }else if(type === "custom_element_tehnofan_contacts"){
      return(
        <Contacts  edit={edit} selectedPage={selectedPage}  {...element}  />
      )

    }else if(type === "custom_element_tehnofan_tldr"){
      return(
        <Tldr  edit={edit} selectedPage={selectedPage}  {...element}  />
      )
    }else if(type === "custom_element_tehnofan_subscription"){
      return (
        <Subscription  edit={edit} selectedPage={selectedPage} {...element}  />
      )
    }else return null

  }
}

const mapStateToProps = ({ webpages, selectedElement, elements }) => {
  return {
    webpages,
    selectedElement,
    elements
  }
}

export default withRouter(connect(mapStateToProps, actions)(withStyles(styles)(TehnofanElement)))
