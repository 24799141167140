
import {
  FETCH_GLOBAL_ELEMENTS,

} from "./../../../actions/types";


export default (state = "loading", action) => {
  switch (action.type) {
    case FETCH_GLOBAL_ELEMENTS:
      return action.payload;
    default:
      return state
  }
};
