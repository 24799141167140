import uniqid from 'uniqid'

export const copyElementTree = (elements, copiedElement, copiedElements)=>{
  if(!copiedElements){
    /// INIT //// ONLY ON FIRST ENTRY
    if(copiedElement.id !== "root"){
      let newElementId = copiedElement.id.split("_")
      newElementId[newElementId.length-1] = uniqid.time()
      newElementId = newElementId.join("_")

      copiedElement.id = newElementId
      copiedElements = {[newElementId]: copiedElement}
    }else{
      copiedElements = {"root": copiedElement}
    }

  }


  copiedElement.children && copiedElement.children.forEach((childId)=>{
    let child = {...elements[childId]}
    if(child.global)return

    let childParent = {...copiedElements[copiedElement.id]}
    child.parent = copiedElement.id

    let newChildId = childId.split("_")
    newChildId[newChildId.length-1] = uniqid.time()
    newChildId = newChildId.join("_")
    child.id = newChildId

    //console.log(copiedElements, copiedElement.id)

      let index = childParent.children.indexOf(childId)
      childParent.children[index] = newChildId



    copiedElements = {...copiedElements, [newChildId]: child}


    if(child && child.children){

       copiedElements = {...copiedElements, ...copyElementTree(elements, child, copiedElements)}
    }

  })

  return copiedElements
}

export const findRootElementId = (elementChildren)=>{

  let rootElementId = ""

  Object.entries(elementChildren).forEach(([elementId, element])=>{
    if(!elementChildren[element.parent]){
      rootElementId = elementId
      return true
    }
  })

  return rootElementId
}

export const getAllElementChildren = (elements, copiedElement, copiedElementChildren)=>{

  copiedElement.children.forEach((childId)=>{
    copiedElementChildren.push(childId)
    let child = elements[childId]

    if(child && child.children){
      getAllElementChildren(elements, child, copiedElementChildren)
    }

  })
  return copiedElementChildren
}


export const getAllElementChildrenObjects = (elements, copiedElement, copiedElementChildren)=>{

  copiedElement.children.forEach((childId)=>{
    let child = elements[childId]
    copiedElementChildren  = {...copiedElementChildren, [childId]: child}

    if(child.children){
      getAllElementChildrenObjects(elements, child, copiedElementChildren)
    }

  })
  return copiedElementChildren
}
