import * as defaultCss from './defaultCss'
import tehnofanElemmentsConstructor from './custom/tehnofan/tehnofanElementsConstructor'

/*
####################################################
###################################################
##################VARIABLES#######################

name(String) - container description
childrens(Array) - contains ids of possible children inputs
properties(Array) - container properties
  label - property description
  type[text, selection, selectionArray, checkbox] - input type
  id - input id
  value - input value
  if(type === selection) values - select input values
  default - default input value
  linkedTo - id of input that it is linked to
  linkedValue - value shown in selection
  valuesQuery - ?
  basic - basic inputs (for switching between advanced and normal mode)
  groupBy[content, look, function, image] - for easier grouping of inputs inside forms
*/


const GRID_VALUES = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
const DEFAULT_CSS_CLASSES = defaultCss.allClasses

const grid_container = {

  name:"Grid container",
  properties:[
    {
      label:"Name", type:"text", id: "name", value: "", default: "", groupBy: "function"
    },
    {
      label:"Classes", type:"selection", id: "classNames", values: DEFAULT_CSS_CLASSES, default: [], groupBy: "look"
    },
    {
      label:"Styles", type:"selectionArray", id: "style", values: [], default: [], groupBy: "look"
    },
    {
      label:"Aligment", type:"selection", id: "justify", values: [ "center", "flex-start", "flex-end", "space-between", "space-around"], default: "center", groupBy: "look"
    },
    {
      label: "Direction", type:"selection", id: "direction", values:["row", "column"], default: "row", groupBy: "look"
    },
    {
      label: "Item aligment", type:"selection", id: "alignItems", values: [ "center", "flex-start", "flex-end", "space-between", "space-around"], default: "center", groupBy: "look"
    },
    {
      label:"Spacing", type:"text", id: "spacing", value: "", default: "", groupBy: "look"
    },

  ],

}

const grid_item = {

  name:"Grid item",
  properties:[
    {
      label:"Name", type:"text", id: "name", value: "", default: "", groupBy: "function"
    },
    {
      label:"Classes", type:"selection", id: "classNames", values: DEFAULT_CSS_CLASSES, default: [], groupBy: "look"
    },
    {
      label:"Styles", type:"selectionArray", id: "style", values: [], default: [], groupBy: "look"
    },
    {
      label:"Select background image", type:"fileUpload", id: "fileUrl", value:  "", default: "", groupBy: "image", basic: true
    },
    {
      label:"Extra large devices", type:"selection", id: "xl", values: GRID_VALUES, default: 12, groupBy: "look"
    },
    {
      label:"Large devices", type:"selection", id: "lg", values: GRID_VALUES, default: 12, groupBy: "look"
    },
    {
      label:"Medium devices", type:"selection", id: "md", values: GRID_VALUES, default: 12, groupBy: "look"
    },
    {
      label:"Small devices", type:"selection", id: "sm", values: GRID_VALUES, default: 12, groupBy: "look"
    },
    {
      label:"Extra small devices", type:"selection", id: "xs", values: GRID_VALUES, default: 12, groupBy: "look"
    },
  ],
}

const carousel_element = {

  name: "Carousel",
  properties: [
    {
      label:"Name", type:"text", id: "name", value: "", default: "", groupBy: "function"
    },
    {
      label:"Classes", type:"selection", id: "classNames", values: DEFAULT_CSS_CLASSES, default: [], groupBy: "look"
    },
    {
      label:"Styles", type:"selectionArray", id: "style", values: [], default: [], groupBy: "look"
    },
    {
      label:"Select background image", type:"fileUpload", id: "fileUrl", value:  "", default: "", groupBy: "image", basic: true

    },
    {
      label:"Disable buttons", type:"checkbox", id: "disableButtons", value: "", default: "", groupBy: "function"
    },
    {
      label:"Extra large devices", type:"selection", id: "xl", values: GRID_VALUES, default: 12, groupBy: "look"
    },
    {
      label:"Large devices", type:"selection", id: "lg", values: GRID_VALUES, default: 12, groupBy: "look"
    },
    {
      label:"Medium devices", type:"selection", id: "md", values: GRID_VALUES, default: 12, groupBy: "look"
    },
    {
      label:"Small devices", type:"selection", id: "sm", values: GRID_VALUES, default: 12, groupBy: "look"
    },
    {
      label:"Extra small devices", type:"selection", id: "xs", values: GRID_VALUES, default: 12, groupBy: "look"
    },
  ],
  contains: [
    "grid_item"
  ]
}

const box_element = {

  name: "Box element",
  properties: [
    {
      label:"Name", type:"text", id: "name", value: "", default: "", groupBy: "function"
    },
    {
      label:"Classes", type:"selection", id: "classNames", values: DEFAULT_CSS_CLASSES, default: [], groupBy: "look"
    },
    {
      label:"Styles", type:"selectionArray", id: "style", values: [], default: [], groupBy: "look"
    },
    {
      label:"Select background image", type:"fileUpload", id: "fileUrl", value:  "", default: "", groupBy: "image", basic: true

    },
  ],

}

const button_element = {

  name:"Button element",
  properties:[
    {
      label:"Name", type:"text", id: "name", value: "", default: "", groupBy: "function"
    },
    {
      label:"Classes", type:"selection", id: "classNames", values: DEFAULT_CSS_CLASSES, default: [], groupBy: "look"
    },
   
    {
      label:"Label", type:"text", id: "label", value: "", default: "", basic: true, groupBy: "content"
    },
    {
      label: "Variant", type:"selection", id: "variant", values:["contained", "outlined", "text"], default: "text", groupBy: "look"
    },
    {
      label: "Text align", type:"selection", id: "align", values: [ "center", "left", "right"], default: "center", groupBy: "look"
    },
    {
      label: "Color", type:"selection", id: "color", values: [ "inherit", "primary", "secondary", "default"], default: "primary", groupBy: "look"
    },
    {
      label: "Button type", type:"selection", id: "buttonType", values: [ "Redirect", "Path"], default: "Path", basic: true, groupBy: "function"
    },
    {
      label:"Redirect to", id: "redirect", linkedTo: "buttonType", linkedValue:"Redirect", type:"text",  value: "", default: "", basic: true, groupBy: "function"
    },
    {
      label:"Go to", id: "path", linkedTo: "buttonType", linkedValue: "Path", type:"selection",  valuesQuery: "allpages", default: "", basic: true, groupBy: "function"
    },
    {
      label: "Type", type:"selection", id: "animationType", values:["fade","grow", "slide", "collapse"] , default: "", groupBy: "animations"
    },
    {
      label: "Delay", type:"text", id: "animationDelay", value: "", default: "", groupBy: "animations"
    },
    {
      label: "Direction", type:"selection", id: "animationDirection",  values:["up","down", "left", "right" ], default: "", groupBy: "animations"
    },

  ],

}

const text_element = {

  name:"Text element",
  properties:[
    {
      label:"Name", type:"text", id: "name", value: "", default: "", groupBy: "function"
    },
    {
      label:"Content", type:"text", id: "content", value: "", default: "", basic: true, groupBy: "content"
    },
    {
      label:"Classes", type:"multipleSelection", id: "classNames", values: DEFAULT_CSS_CLASSES, default: [], groupBy: "look"
    },
    {
      label:"Styles", type:"selectionArray", id: "style", values: [], default: [], groupBy: "look"
    },
    {
      label:"Aligment", type:"selection", id: "align", values: [ "center", "left", "right", "justify"], default: "center", groupBy: "look"
    },
    {
      label: "Variant", type:"selection", id: "variant", values:["body1","h1", "h2", "h3", "h4", "h5", "h6",] , default: "body1", groupBy: "look"
    },
    {
      label: "Color", type:"selection", id: "color", values: [ "inherit", "primary", "secondary", "default"], default: "primary", groupBy: "look"
    },
    {
      label: "Type", type:"selection", id: "animationType", values:["fade","grow", "slide", "collapse"] , default: "", groupBy: "animations"
    },
    {
      label: "Delay", type:"text", id: "animationDelay", value: "", default: "", groupBy: "animations"
    },
    {
      label: "Direction", type:"selection", id: "animationDirection",  values:["up","down", "left", "right" ], default: "", groupBy: "animations"
    },

  ],

}

const image_element = {

  name:"Image element",
  properties:[
    {
      label:"Name", type:"text", id: "name", value: "", default: "", groupBy: "function"
    },
    {
      label:"Select image", type:"fileUpload", id: "fileUrl", value:  "", default: "", basic: true, groupBy: "image"
    },
    {
      label:"Classes", type:"selection", id: "classNames", values: DEFAULT_CSS_CLASSES, default: [], groupBy: "look"
    },
    {
      label:"Styles", type:"selectionArray", id: "style", values: [], default: [], groupBy: "look"
    },
    {
      label:"Redirect to", id: "redirect", type:"text",  value: "", default: "", basic: true, groupBy: "function"
    },
    {
      label:"Position in document", id: "documentPosition", type:"selection", values:[0,1,2,3,4,5],  value: 0, default: 0, groupBy: "animation"
    },

  ],

}

const background_element = {

  name:"Background element",
  properties:[
    {
      label:"Name", type:"text", id: "name", value: "", default: "", groupBy: "function"
    },
    {
      label:"Background img", type:"fileUpload", id: "file", value:  "", default: "", basic: true
    },

    {
      label:"Styles", type:"selectionArray", id: "style", values: [], default: []
    },

  ],

}

const google_maps_element = {

  name:"Google maps element",
  properties:[
    {
      label:"Name", type:"text", id: "name", value: "", default: "", groupBy: "function"
    },
    {
      label:"Offset height", type:"text", id: "offset", value: "", default: "", groupBy: "look"
    },
    {
      label:"Minimum height", type:"text", id: "minHeight", value: "", default: "", groupBy: "look"
    },
    {
      label:"Latitude", type:"text", id: "latitude", value: "", default: "", groupBy: "function"
    },
    {
      label:"Longitude", type:"text", id: "longitude", value: "", default: "", groupBy: "function"
    },
    {
      label:"Zoom", type:"text", id: "zoom", value: "", default: "", groupBy: "function"
    },
    {
      label:"Google maps API key", type:"text", id: "key", value: "", default: "", groupBy: "function"
    },

  ],

}

const searched_hits_element = {

  name: "Searched hits element",
  properties: [
    {
      label:"Name", type:"text", id: "name", value: "", default: "", groupBy: "function"
    },
  ],

}


export default {
  grid_container,
  grid_item,
  background_element,
  button_element,
  text_element,
  image_element,
  google_maps_element,
  carousel_element,
  box_element,
  searched_hits_element,
  ...tehnofanElemmentsConstructor
}
