const FirebaseConfig = {
  apiKey: "AIzaSyD7mwOMpkvPscTWBQi5UVx0wbamCt6ZKaw",
  authDomain: "tehnofan.firebaseapp.com",
  databaseURL: "https://tehnofan.firebaseio.com",
  projectId: "tehnofan",
  storageBucket: "tehnofan.appspot.com",
  messagingSenderId: "204973539936",
  appId: "1:204973539936:web:b1f680eb0df243db",
  measurementId: "G-0NP015CLZX",
  };

module.exports = {FirebaseConfig}
