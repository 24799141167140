import { combineReducers } from "redux";
import pagesReducer from './api/webpage/pagesReducer';
import modalStatusReducer from './component/modalStatusReducer';
import drawerStatusReducer from './component/drawerStatusReducer';
import selectedElementReducer from './session/selectedElementReducer'
import selectedPageReducer from './session/selectedPageReducer'
import elementsReducer from './api/webpage/elementsReducer'
import authReducer from './session/authReducer'
import pagesInfoReducer from './api/webpage/pagesInfoReducer'
import subscriptionsReducer from './api/tehnofan/subscriptionsReducer'
import usersReducer from './api/webpage/usersReducer'
import activeLanguageReducer from './session/activeLanguageReducer'
import dictionaryReducer from './api/webpage/dictionaryReducer'
import activeDocumentPageReducer from './session/activeDocumentPageReducer'
import globalElementsReducer from './api/webpage/globalElementsReducer'
import pageChangeHistoryReducer from './session/pageChangeHistoryReducer'
import globalElementsLoadedReducer from './session/globalElementsLoadedReducer'

export default combineReducers({
  webpages: pagesReducer,
  webpagesInfo: pagesInfoReducer,
  modalStatus: modalStatusReducer,
  selectedPage: selectedPageReducer,
  selectedElement: selectedElementReducer,
  drawerStatus: drawerStatusReducer,
  auth: authReducer,
  elements: elementsReducer,
  subscriptions: subscriptionsReducer,
  users: usersReducer,
  activeLanguage: activeLanguageReducer,
  dictionary: dictionaryReducer,
  activeDocumentPage: activeDocumentPageReducer,
  globalElements: globalElementsReducer,
  pageChangeHistory: pageChangeHistoryReducer,
  globalElementsLoaded: globalElementsLoadedReducer,
});
