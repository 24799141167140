import React from 'react';
import PropTypes from 'prop-types';

import * as actions from './../../../../redux/actions'
import { connect } from "react-redux";

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Fade from '@material-ui/core/Fade';

import Element from './../../../Element'
import editWrapper from './../../../default/wrappers/editWrapper'

import parseStringToHtml from './../../../../helpers/parseStringToHtml'
import * as defaultCss from './../../../defaultCss'
import classNames from 'classnames'


const styles = theme => ({

  ...defaultCss.styles(theme),
  root: {
    position: "relative",
    minHeight: 400,
    width: "100%",
    background: "white",


  },
  column:{
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems: "center",

  },
  title:{
    position: "relative",
    padding: theme.spacing(3)
  },
  container:{

    height: "100%",
    width: "100%",
    position: "relative"
  }

});



function Content(props) {
  const { classes, title, children, fileUrl, style, edit, selectedPage, contentAlignment, activeDocumentPage, documentPosition } = props;



  let backgroundImage = {
    position:"absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background:  "linear-gradient(to bottom, #FFFFFF 0%, rgba(255, 255, 255, 0.3) 25%, rgba(255, 255, 255, 0.1) 75%),"+
                 "linear-gradient(to top, #FFFFFF 0%, rgba(255, 255, 255, 0.3) 25%, rgba(255, 255, 255, 0.1) 75%),"+
                 "linear-gradient(to right, #FFFFFF 0%, rgba(255, 255, 255, 0.3) 25%, rgba(255, 255, 255, 0.1) 75%),"+
                 `linear-gradient(to left, #FFFFFF 0%, rgba(255, 255, 255, 0.3) 25%, rgba(255, 255, 255, 0.1) 75%), url(${fileUrl})`,

   backgroundRepeat: "no-repeat",
   backgroundSize: "cover",
   backgroundPosition: "center"


  }

  const content = (
    <div  className={classNames(classes.container, classes.column)}>
      <Fade in={documentPosition ? activeDocumentPage >= documentPosition : true} timeout={2000} mountOnEnter>
        <span style={backgroundImage}></span>
      </Fade>
      {title && <Typography className={classNames(classes.paragraphTitle, classes.title)}>{parseStringToHtml(title)}</Typography>}
      <Grid container justify={contentAlignment ? contentAlignment : "center"} style={{position: "relative"}}>
        {children && children.map((child)=> <Element key={child} edit={edit} selectedPage={selectedPage} elementId={child}/>)}
      </Grid>
    </div>

  )

  return (
    <div style={style ?  style.reduce((obj, tmp)=> {return {...obj, [tmp.name]: tmp.value}},{}) : null}  className={classNames(classes.root, classes.column)}>

        { content}
    </div>
  );
}

Content.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = ({ activeDocumentPage }) => {
  return {

    activeDocumentPage
  }
}

export default connect(mapStateToProps, actions)(withStyles(styles)(editWrapper(Content)))
