
import { SET_SELECTED_PAGE } from "./../../actions/types";


export default (state = "_home", action) => {
  switch (action.type) {
    case SET_SELECTED_PAGE:
      return action.payload;
    default:
      return state;
  }
};
