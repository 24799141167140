import * as supportFunctions from './../../supportFunctions'
import * as firebase from './../../../../config/firebase'

import {
  SET_SELECTED_PAGE,
  FETCH_ALL_PAGES,
  FETCH_PAGE,
  ADD_NEW_PAGE,
  FETCH_PAGE_ELEMENTS,
  SET_ACTIVE_MODAL,
  ADD_PAGE_INFO,
  DELETE_PAGE,
  FETCH_PAGES_INFO
} from './../../types'


export const fetchWebpagesInfo = () => async dispatch =>{
  console.log("fetchWebpagesInfo")
  let webpagesInfo = await firebase.pagesInfoRef.once("value")

  dispatch({
    type: FETCH_PAGES_INFO,
    payload: webpagesInfo.val()
  })
}

export const fetchWebpages = () => async dispatch =>{
  console.log("fetchWebpages")
  let webpages = await firebase.pagesRef.once("value")
  webpages = webpages.val()

  let elements = {}

  Object.keys(webpages).forEach((webpageId)=>{
    elements = {...elements, ...webpages[webpageId].elements}
  })

  dispatch({
    type: FETCH_ALL_PAGES,
    payload: webpages
  })

}

export const fetchWebpageById = (id) => async (dispatch, getState) =>{

  // check if page is already loaded
  if(getState().webpages[id]){
    console.log("fetchWebpageByIdFromStore")
    //sets right elemenets to display on page view
    dispatch({
      type: FETCH_PAGE_ELEMENTS,
      payload: getState().webpages[id].elements
    })

    return true
  }

  console.log("fetchWebpageById")
  let webpage = await firebase.pagesRef.child(id).once("value")
  webpage = webpage.val()
  let elements = webpage && webpage.elements
  
  //page do not exist
  if(!elements) return false

  dispatch({
    type: FETCH_PAGE,
    payload: {[id]:webpage}
  })

  //sets right elemenets to display on page view
  dispatch({
    type: FETCH_PAGE_ELEMENTS,
    payload: elements
  })

  return true
}

export const refetchWebpageById = (id) => async (dispatch, getState) =>{
  // FORCING REFETCH, fetching page without checking if already exsist in store
  console.log("refetchWebpageById")
  let webpage = await firebase.pagesRef.child(id).once("value")
  webpage = webpage.val()
  let elements = webpage.elements


  dispatch({
    type: FETCH_PAGE,
    payload: {[id]:webpage}
  })

  //sets right elemenets to display on page view
  dispatch({
    type: FETCH_PAGE_ELEMENTS,
    payload: elements
  })

  return true
}


export const addNewPage = (values) => async (dispatch, getState) => {

  let pageId = values.urlPath.replace(/\//g,"_")

  let pageData = {
    ...values,
    elements:{
      root:{
        id: "root",
        name: "root",
        children: [...values.globalElements]
      }
    }
  }

  

  firebase.databaseRef.update({
    [`/pages/${pageId}`]:pageData,
    [`pagesInfo/${pageId}`]: values,
  }).then(()=>{
    dispatch({
      type: ADD_NEW_PAGE,
      payload: {[pageId]: {
        name: values.pageName,
        path: values.pageUrlPath
      }}
    })

    dispatch({
      type: ADD_PAGE_INFO,
      payload: {[pageId]: {
        name: values.pageName,
      }}
    })

  }).catch((err)=>{
    console.log(err)
  })

}

export const editPageInfo = (values, selectedPage) => async dispatch => {
  let urlPath = values.urlPath.replace(/\//g,"_")

  let editedData = {
    [`/pages/${urlPath}/name`]: values.name,
    [`/pages/${urlPath}/title`]: values.title,
    [`/pages/${urlPath}/urlPath`]: values.urlPath,
    [`/pages/${urlPath}/description`]: values.description,
    [`pagesInfo/${urlPath}`]: values,
  }

  if(selectedPage !== urlPath){
    editedData = {
      ...editedData,
      [`/pages/${selectedPage}`]: null,
      [`pagesInfo/${selectedPage}`]: null,
    }
   
  }

  await firebase.databaseRef.update(editedData)

  
  // Need to change webpagesInfo and webpages session object? 
  // Page is now being reloaded after this action, so no need for session changes!!!

}

export const copyPage = (values) => async (dispatch, getState) =>{

  let pageId = values.copiedPageId

  let webpage = getState().webpages[pageId]
  let elements = getState().elements
  let element = webpage.elements["root"]

  let newPageId = values.pageUrlPath.replace(/\//g, "_")

  let newPageElements = supportFunctions.copyElementTree(elements ,element, null)

  firebase.databaseRef.update({
    [`/pages/${newPageId}/`]: {
       name: values.pageName,
       path: values.pageUrlPath,
       elements: newPageElements
    },
    [`/pagesInfo/${newPageId}/`]: {
      name: values.pageName,
    }
  }).then(()=>{
    dispatch({
      type: ADD_NEW_PAGE,
      payload: {[newPageId]: {
        name: values.pageName,
        path: values.pageUrlPath,
        elements: newPageElements
      }}
    })
    dispatch({
      type: FETCH_PAGE_ELEMENTS,
      payload: newPageElements
    })
    dispatch({
      type: SET_ACTIVE_MODAL,
      payload: ""
    })
    dispatch({
      type: SET_SELECTED_PAGE,
      payload: newPageId
    })
  }).catch((err)=>{
    console.log(err)
  })



}


export const deletePage = (pageId) => async dispatch => {
  firebase.databaseRef.update({
    [`/pages/${pageId}/`]: null,
    [`/pagesInfo/${pageId}/`]: null
  }).then(()=>{
    dispatch({
      type: DELETE_PAGE,
      pageId
    })
  }).catch((err)=>{
    console.log(err)
  })

}
