import React from 'react';
import { withRouter } from 'react-router'

import * as actions from './../../../../redux/actions'
import {connect} from "react-redux";

import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import Typography from '@material-ui/core/Typography';

import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import MenuIcon from '@material-ui/icons/Menu';
import CallIcon from '@material-ui/icons/Call';
import MailIcon from '@material-ui/icons/Mail';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PhoneIcon from '@material-ui/icons/PhoneAndroid';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';

import classNames from 'classnames'
import Fab from '@material-ui/core/Fab';

import Element from './../../../Element'

import SearchInput from './../../../components/SearchInput'

import editWrapper from './../../../default/wrappers/editWrapper'

const NAVBAR_HEIGHT = 70

const CONTAINER_HEIGHT = 200

const TOP_SMALL_CONTAINER_HEIGHT = 80



const styles = theme => ({
  root: {
    textAlign: 'center',
    position:"relative",

    background: "white",

    zIndex: 1000,

    justifyContent: "flex-end",

    [theme.breakpoints.down('sm')]: {

      height: NAVBAR_HEIGHT,
    },

    [theme.breakpoints.up('md')]: {

      height: CONTAINER_HEIGHT,
    },
    [theme.breakpoints.up('lg')]: {

      height: CONTAINER_HEIGHT,
    },

  },

  column:{
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems: "center",

  },
  row:{
    display:"flex",
    flexDirection:"row",
    justifyContent:"center",
    alignItems: "center",
  },

  topContainer:{
    position: "fixed",
    top: 0,
    background: "white",
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    width: "100%",
    height: CONTAINER_HEIGHT - NAVBAR_HEIGHT,

    transition: "height .35s ease-in-out",
  },
  buttonText:{
    textTransform: "initial",
    fontWeight: 300,
    fontSize: 17,
    color: theme.palette.primary.main
  },

  navbar:{
    boxShadow: theme.shadows[3],
    position: "fixed",
    top: CONTAINER_HEIGHT - NAVBAR_HEIGHT,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    width: "100%",
    height: NAVBAR_HEIGHT,
    background: theme.palette.background.secondary,

    transition: "top .35s ease-in-out",

  },

  logo:{
    cursor: "pointer",

    paddingBottom: theme.spacing.unit * 1,

    [theme.breakpoints.up('md')]: {

      height: "40%",
    },
    [theme.breakpoints.up('lg')]: {

      height: "50%",
    },
    [theme.breakpoints.up('xl')]: {

      height: "62%",
    },
  },

  logoText1:{
    fontWeight: 200,

    color:"white",


    [theme.breakpoints.up('md')]: {
      fontSize: 20,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 24,
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: 35,
    },
  },
  logoText2:{
    fontWeight: 500,

    color: theme.palette.secondary.text,


    [theme.breakpoints.up('md')]: {
      fontSize: 20,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 25,
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: 35,
    },
  },
  navSmall:{
    position:"fixed",
    top: 0,
    width: "100%",
    height: NAVBAR_HEIGHT,
    boxShadow: theme.shadows[2],
    background: "white"
  },
  menuButton:{

    position: "fixed",
    bottom: 16,
    right: 16,
    boxShadow: "none",
    cursor: "pointer"
  },

  drawerContainer:{

    position: "relative",
    width: "100%",
    background: theme.palette.background.secondary,
    paddingBottom: NAVBAR_HEIGHT
  },

  infoText:{
    color: theme.palette.primary.main,
    fontSize: 18,
    fontWeight: 300,
    paddingLeft: 8
  },
  infoContainer:{
    width: "100%",
    flexWrap: "wrap",
    justifyContent:"space-around"
  },
  infoRow:{

    justifyContent:"flex-start",
    paddingRight: 24,

  },
  infoIcon:{
    fontSize: 35,
    color: "lightgray",

  }
});




class Navigation extends React.Component {
  state = {
    scrollPosition: 0,
    drawerActive: false,
  };

  componentDidMount() {
    window.addEventListener('scroll', this.listenToScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll',  this.listenToScroll)
  }

  componentDidUpdate(prevPros){
    if(this.props.location !== prevPros.location)this.toggleDrawer()
  }

  goTo = (path) => {
    !this.props.edit && this.props.history.push(path)
  }

  redirectToShop = () => {
    if(!this.props.edit) window.location.href = "https://www.filtri-za-vodo.si/"
  }

  redirectTopVega = ()=>{
    if(!this.props.edit) window.location.href = "https://tehnofan-vega.si/"
  }

  listenToScroll = (e) => {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop

    this.setState({
      scrollPosition: winScroll,

    })
  }

  toggleDrawer = () => {
    this.setState({drawerActive: !this.state.drawerActive})
  }

  handleLanguageChange = async (languageId)=>{

    if(languageId && (!this.props.dictionary.data || !this.props.dictionary.data[languageId]))await this.props.fetchDictionaryLanguageData(languageId)

    this.props.setActiveLanguage(languageId)
  }


  render() {
    const { classes, children, fileUrl, dictionary, edit } = this.props;
    const { scrollPosition } = this.state

    let currTopContainerSize = CONTAINER_HEIGHT - NAVBAR_HEIGHT - scrollPosition

    let topContainerStyle = {
      height: TOP_SMALL_CONTAINER_HEIGHT < currTopContainerSize ? currTopContainerSize : TOP_SMALL_CONTAINER_HEIGHT,

    }

    topContainerStyle = edit ? {position: "relative"} : topContainerStyle


    let navbarStyle = {
      top: TOP_SMALL_CONTAINER_HEIGHT < currTopContainerSize ? currTopContainerSize : TOP_SMALL_CONTAINER_HEIGHT
    }

    navbarStyle = edit ? {position: "inherit", top: 0} : navbarStyle

    return (
      <div className={classNames(classes.root, classes.column)}>
        <Hidden smDown>
          <Grid container className={classes.topContainer} style={topContainerStyle} justify="center" >
            <Grid md={4} lg={4} xl={4} item  style={{ display: "flex", alignItems: "center", height: "100%"}}>

              <img onClick={()=>this.goTo("/")} alt="tehnofan logo" src="https://firebasestorage.googleapis.com/v0/b/tehnofan.appspot.com/o/images%2FlogoTehnofan-min.png?alt=media&token=d1ae3446-22f1-4f36-8e92-10cf6621304e" className={classes.logo} height="75%" width="auto"/>
              <img onClick={()=>this.redirectTopVega()} alt="vega logo" src="https://firebasestorage.googleapis.com/v0/b/tehnofan.appspot.com/o/images%2FlogoVega-min.png?alt=media&token=ca543634-4abf-483c-bd40-3462a6147121" className={classes.logo} height="75%" width="auto"/>

            </Grid>
            <Grid md={4} lg={4} xl={4} item className={classes.column} style={{alignItems: "center", height: "100%"}}>
              <div className={classNames(classes.row, classes.infoContainer)}>
                <div className={classNames(classes.row, classes.infoRow)}>
                  <PhoneIcon className={classes.infoIcon}/>
                  <Typography color="inherit" className={classes.infoText}>(041) 687 090</Typography>
                </div>
                <div className={classNames(classes.row, classes.infoRow)}>
                  <CallIcon className={classes.infoIcon}/>
                  <Typography color="inherit" className={classes.infoText}>(01) 4274 476</Typography>
                </div>
                <div className={classNames(classes.row, classes.infoRow)}>
                  <MailIcon className={classes.infoIcon}/>
                  <Typography color="inherit" className={classes.infoText}>info@tehnofan.si</Typography>
                </div>
              </div>
            </Grid>
            <Grid md={4} lg={4} xl={4} item className={classes.column} style={{alignItems: "flex-end"}}>

              <div className={classes.row} style={{justifyContent: "flex-end", width: "100%"}}>


                  <div className={classes.row}>
                    <Box onClick={()=>this.handleLanguageChange(null)} style={{cursor: "pointer"}} pr={3}>
                      <img src="https://firebasestorage.googleapis.com/v0/b/tehnofan.appspot.com/o/images%2FFlag_of_Slovenia-min.png?alt=media&token=a6a5614b-1197-4e25-ab2a-1435847466d2" height="24px" width="auto" alt="slo"/>
                    </Box>
                    {dictionary !== "loading" && Object.keys(dictionary.languages).map( language => <Box onClick={()=>this.handleLanguageChange(language)} style={{cursor: "pointer"}} key={language} pr={3}><img src={dictionary.languages[language].icon} height="24px" width="auto" alt={language}/></Box>)}

                  </div>
                  <div style={{marginBottom: 16, display: "flex", flexDirection: "row", alignItems: "center", width: 200}}>
                      <SearchInput />
                  </div>
                  <div>
                    <Button onClick={this.redirectToShop} style={{marginLeft: 24}}>
                      <ShoppingCartIcon style={{fontSize: 45, marginRight: 8, color:"lightgray"}}/>

                      <Typography className={classes.buttonText}>Spletna <br/> trgovina </Typography>
                    </Button>
                  </div>
                </div>

            </Grid>
          </Grid>


          <Grid container justify="space-around" style={navbarStyle} className={classes.navbar}>
            <Grid item md={3} lg={4} xl={4} className={classes.row} style={{justifyContent: "flex-start"}}>
              <Typography className={classes.logoText1}>Strokovnjaki za <span className={classes.logoText2}>čisto vodo</span></Typography>

            </Grid>
            <Grid item md={9} lg={8} xl={8} className={classes.row} style={{justifyContent: "flex-end", height: "100%"}}>
              {
                children && children.map((child)=>{
                  return <Element edit={this.props.edit} selectedPage={this.props.selectedPage} key={child} elementId={child} />
                })
              }
            </Grid>
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <React.Fragment>

            <div className={classNames(classes.column, classes.navSmall)} >
              <img onClick={()=>this.goTo("/")} alt="tehnofan logo" src={fileUrl} className={classes.logo} height="70%" width="auto"/>
            </div>

            <Drawer classes={{docked: classes.drawer}} anchor="bottom" open={this.state.drawerActive} onClose={this.toggleDrawer}>

                <div className={classNames(classes.drawerContainer, classes.column)} style={{color: "black", justifyContent:"flex-end"}}>
                  {
                    children && children.map((child)=>{
                      return <Element edit={this.props.edit} selectedPage={this.props.selectedPage} key={child} elementId={child} drawer={true}/>
                    })
                  }
                </div>
                <Fab onClick={this.toggleDrawer}  color="primary" className={classes.menuButton} >
                  <CloseIcon/>
                </Fab>
            </Drawer>

            <Fab onClick={this.toggleDrawer}  color="primary" className={classes.menuButton} >
              <MenuIcon/>
            </Fab>

          </React.Fragment>

        </Hidden>
      </div>
    );
  }
}

Navigation.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

const mapStateToProps = ({dictionary, scrollPosition}) => {
  return {dictionary, scrollPosition}
}

export default withRouter(connect(mapStateToProps, actions)(withStyles(styles)(editWrapper(Navigation))))


/*
<Grid container justify={"center"}>
  <Grid item className={classes.infoWrapper}  sm={6} md={6} lg={4}>
    <InfoWrapper variant="contained"   icon={<LocationIcon className={classes.infoIconWhiteSmall} />} text={["Ilovški štradon 41a","Ljubljana, 1000"]} {...this.props}/>
  </Grid>

  <Grid item className={classes.infoWrapper} sm={6} md={6} lg={4}>
    <InfoWrapper variant="contained"  icon={<TimeIcon className={classes.infoIconWhiteSmall}/>} text={["Pon-Pet","8.30-16.30"]} {...this.props}/>
  </Grid>

  <Grid item className={classes.infoWrapper} sm={6} md={6} lg={4}>
    <InfoWrapper variant="contained"  icon={<CallIcon className={classes.infoIconWhiteSmall}/>} text={["info@tehnofan.si","041 687 090"]} {...this.props}/>
  </Grid>
</Grid>
    */
