import React, { useState } from 'react';
import classNames from 'classnames'
import PropTypes from 'prop-types';
import * as actions from './../../../../redux/actions'
import { connect } from "react-redux";
import { withRouter } from 'react-router'
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import Grid from '@material-ui/core/Grid';

import Grow from '@material-ui/core/Grow';

import Element from './../../../Element'
import * as defaultCss from './../../../defaultCss'

import translateElement from './../../../default/wrappers/translateElement'
import editWrapper from './../../../default/wrappers/editWrapper'

const CIRCLE_SIZE_XL = 240
const CIRCLE_SIZE_LG = 220
const CIRCLE_SIZE_MD = 180
const CIRCLE_SIZE_SM = 160
const CIRCLE_SIZE_XS = 140

const styles = theme => ({
  ...defaultCss.styles(theme),

  root: {
    position: "relative",
    display: "flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems: "center",

    cursor: "pointer",
    padding: theme.spacing.unit * 3,
  },



  //// IMAGE /////

  iconWrapperImage:{
    position: "relative",

    [theme.breakpoints.up('xs')]: {
      width: CIRCLE_SIZE_XS,
      height: CIRCLE_SIZE_XS,
      borderRadius: CIRCLE_SIZE_XS,
    },
    [theme.breakpoints.up('sm')]: {
      width: CIRCLE_SIZE_SM,
      height: CIRCLE_SIZE_SM,
      borderRadius: CIRCLE_SIZE_SM,
    },
    [theme.breakpoints.up('md')]: {
      width: CIRCLE_SIZE_MD,
      height: CIRCLE_SIZE_MD,
      borderRadius: CIRCLE_SIZE_MD,
    },
    [theme.breakpoints.up('lg')]: {
      width: CIRCLE_SIZE_LG,
      height: CIRCLE_SIZE_LG,
      borderRadius: CIRCLE_SIZE_LG,
    },
    [theme.breakpoints.up('xl')]: {
      width: CIRCLE_SIZE_XL,
      height: CIRCLE_SIZE_XL,
      borderRadius: CIRCLE_SIZE_XL,
    },

    fontSize: 45,
    color:"white",

    display: "flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems: "center",


    overflow: "hidden",
    transition: "boxShadow .15s ease-in-out",

    cursor: "pointer"
  },
  iconWrapperImageActive:{

    boxShadow: "none",

    transition: "boxShadow .15s ease-in-out",
  },
  image:{
    zIndex: 0,
    position:"absolute",
    top: 0,
    left: 0,
  },

  labelImage:{

    zIndex: 20,
    padding: theme.spacing.unit * 1,

    fontWeight: 200,

    color: "white",

    [theme.breakpoints.up('xs')]: {
      fontSize: 16,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 23,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 28,
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: 30,
    },


  },

  labelImageActive:{
    color: theme.palette.primary.main,
    fontWeight: 300,
    [theme.breakpoints.up('xs')]: {
      fontSize: 18,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 20,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 24,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 30,
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: 32,
    },
  },
  labelContainer:{
    position: "relative",
    zIndex: 11,
    marginTop: theme.spacing.unit * 1,
    background: theme.palette.primary.main,
    opacity: 0.95,
    width: "100%",
    transition: "background .15s ease-in-out",
    minHeight: 70,


    [theme.breakpoints.down('sm')]: {
      minHeight: "inherit",
    },

    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  labelContainerActive:{
    background: "none",
    transition: "background .15s ease-in-out",
  },
  overlay:{
    zIndex: 10,
    position:"absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    background: "rgba(255,255,255,0.25)",
    transition: "background .25s ease-in-out",

  },
  overlayActive:{
    zIndex: 10,
    position:"absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    background: "rgba(255,255,255,0.75)",
    transition: "background .25s ease-in-out",
  }

});



function CircleImageButton(props) {
  const {
    classes,
    icon,
    title,
    children,
    xs,
    sm,
    md,
    lg,
    edit,
    buttonType,
    redirect,
    path,
    fileUrl,
    history,
    animationDurration,
  } = props;
  const [active, setActive] = useState(false);

  let circleStyle = {}

  const handleClick = ()=>{
    if(buttonType === "Redirect") window.location.href = redirect;
    else if(buttonType === "Path") history.push(path)
    else if(buttonType === "Article") props.handleClick()
  }

  if(fileUrl) circleStyle = {background: `url(${fileUrl})`, backgroundSize: "cover",boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.65)"}


  return (
    <Grow in={true} timeout={animationDurration ? Number(animationDurration) : 1000}>
      <Grid
        item
        xs={xs}
        sm={sm}
        md={md}
        lg={lg}
        onMouseEnter={()=>setActive(true)}
        onMouseLeave={()=>setActive(false)}
        className={classNames(classes.column)}
      >

        <div onClick={edit ? null : handleClick} style={circleStyle} className={active ? classNames(classes.iconWrapperImage,classes.iconWrapperImageActive):classes.iconWrapperImage}>
          <span className={active ? classes.overlayActive : classes.overlay }></span>
          <div className={active ? classNames(classes.labelContainer,classes.labelContainerActive):classes.labelContainer }>
            <Typography
               align="center"
               variant={"h4"}
               className={active ? classNames(classes.labelImage, classes.labelImageActive):classes.labelImage}
            >
              {title}
            </Typography>
          </div>
          {icon && icon}
        </div>
        {
          children &&
            <div className={classes.paddingTop}>
              {
                children.map((child)=>{
                  return <Element edit={props.edit} selectedPage={props.selectedPage} key={child} elementId={child} />})
              }
            </div>
        }      
      </Grid>
    </Grow>
  );



}

CircleImageButton.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = () => {
  return {

  }
}

export default withRouter(connect(mapStateToProps, actions)(withStyles(styles)(translateElement(editWrapper(CircleImageButton)))))
