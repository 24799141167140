import React, {useState} from 'react';

import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import Button from '@material-ui/core/Button';

import Box from '@material-ui/core/Box';

import { Formik, Field } from 'formik';
import * as actions from './../../../../redux/actions'
import CustomInputComponent from './../../../components/CustomInputComponent'

import CustomCheckboxRowComponent from './../../../components/CustomCheckboxRowComponent'

import editWrapper from './../../../default/wrappers/editWrapper'

import * as Yup from 'yup';

import * as defaultCss from './../../../defaultCss'

import parseStringToHtml from './../../../../helpers/parseStringToHtml'


const styles = theme => ({

  ...defaultCss.styles(theme),
  root: {
    position: "relative",
    width: "100%",
    padding: theme.spacing(3)
  },
  column:{
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems: "center",

  },
  dialog:{
    borderRadius: 0,
  },
  title:{
    padding: theme.spacing(2),
    width: "100%",
    background: theme.palette.primary.main,
    fontWeight: 200,
    fontSize: 30,
    color: "white"
  },
  container:{
    height: "100%",
    position: "relative"
  },

  article:{

    cursor: "pointer",


    boxShadow: theme.shadows[6]
  },
  articleActive:{

    cursor: "pointer",

    boxShadow: theme.shadows[6],

  },
  articleTitle:{

    display:"flex",
    flexDirection: "column",
    justifyContent: "center",
    transition: "height 0.2s ease-in-out"
  },
  articleTitleActive:{
    display:"flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems:"center",
    height: "100%",
    transition: "height 0.2s ease-in-out"

  },

  description:{
    width: "100%",
    height: "100%",
    overflow: "hidden",
    fontSize: 17,
  },

  smallText:{
    fontSize: 10,
  },

  button:{
    borderRadius: 0,
    textTransform: "initial",
    fontWeight: 300,
  },

  icon:{
    position: "relative",
    zIndex: "20",
    color: theme.palette.primary.main,
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 25,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 30,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 40,
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: 50,
    },
  }

});

function downloadFile(fileName,fileURL) {
    // for non-IE
    if (!window.ActiveXObject) {
        var save = document.createElement('a');
        save.href = fileURL;
        save.target = '_blank';
        var filename = fileURL.substring(fileURL.lastIndexOf('/')+1);
        save.download = fileName || filename;
	       if ( navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) && navigator.userAgent.search("Chrome") < 0) {
				document.location = save.href;
    // window event not working here
			}else{
		        var evt = new MouseEvent('click', {
		            'view': window,
		            'bubbles': true,
		            'cancelable': false
		        });
		        save.dispatchEvent(evt);
		        (window.URL || window.webkitURL).revokeObjectURL(save.href);
			}
    }

    // for IE < 11
    else if ( !! window.ActiveXObject && document.execCommand)     {
        var _window = window.open(fileURL, '_blank');
        _window.document.close();
        _window.document.execCommand('SaveAs', true, fileName || fileURL)
        _window.close();
    }
}

function ArticleModal(props){
  const { classes, article, addSubscribedUser, handleClose, open} = props

  return(
    <React.Fragment>
      <Dialog classes={{paper: classes.dialog}} maxWidth="sm" open={open} onClose={handleClose} >

        <Typography align="center" className={classes.title}>
          {article.title}
        </Typography>


        <Formik
          initialValues={{
            email: "",
            consent: false}}
          onSubmit={(props, action)=>{
            addSubscribedUser(props.email, "article_subscription")
            downloadFile(article.title.replace(/ /g, "_")+".pdf", article.pdfUrl)

            handleClose()
            action.resetForm()

          }}
          validationSchema={Yup.object().shape({
            email: Yup.string().email("E-naslov je npacen!").required()
          })}
        >
          {props => {
            const {
              values,
              handleSubmit,
            } = props;

            return (
              <React.Fragment>
                <DialogContent>

                  <Typography align="justify" className={classes.description}>
                      {parseStringToHtml(article.description)}
                  </Typography>


                  <form onSubmit={handleSubmit}>
                    <Grid style={{paddingTop: 24, paddingBottom: 24}} container spacing={3}>
                      <Grid item sm={12}>
                        <Field
                          label="Moj e-naslov"
                          name="email"
                          component={CustomInputComponent}

                        />
                      </Grid>
                      <Grid item sm={12}>
                        <Field
                          label="Strinjam se s splosnimi pogoji*"
                          name="consent"
                          component={CustomCheckboxRowComponent}
                        />
                      </Grid>
                      <Grid item sm={12}>
                        <Typography align="justify" className={classes.smallText}>
                          *Z oddajo osebnih podatkov potrjujem, da sem seznanjen s splošnimi pogoji.
                          Seznanjen sem, da bo upravljavec moje osebne podatke varoval v skladu z vsakokrat veljavno zakonodajo
                          in jih bo obdeloval zgolj za zgoraj prostovoljno potrjene namene.
                          Potrjujem, da sem seznanjen s svojimi pravicami
                          (vključno s preklicem privolitev k uporabi osebnih podatkov),
                          ki jih lahko uveljavljam po postopku, opredeljenem v splošnih pogojih.
                          Seznanjen sem, da preklic privolitve ne vpliva na zakonitost obdelave na podlagi privolitve pred preklicem le-te.
                        </Typography>
                      </Grid>
                    </Grid>
                  </form>
                </DialogContent>
                <DialogActions>
                  <Button className={classes.button} onClick={handleClose} color="primary">
                    Prekliči
                  </Button>
                  <Button className={classes.button} onClick={handleSubmit} disabled={!values.consent} variant="contained" color="primary">
                    Prenesi članek
                  </Button>
                </DialogActions>
              </React.Fragment>
            );
          }}
        </Formik>

      </Dialog>


    </React.Fragment>

  )
}

function Article(props){
  const {classes, article, edit, withModal} = props
  const [ articleActive, setArticleActive ] = useState(false)
  const [ openModal, setOpenModal ] = useState(false)

  return(
    <React.Fragment>
      <ArticleModal open={openModal} handleClose={()=>setOpenModal(false)} edit={edit} withModal={withModal} article={article} {...props} />
      <Box
        className={articleActive ? classes.articleActive : classes.article}
        height={510}
        position="relative"
        overflow="hidden"
        color="primary.main"
        onMouseEnter={()=>setArticleActive(true)}
        onMouseLeave={()=>setArticleActive(false)}
      >
        <Box
          height={70}
          className={articleActive ? classes.articleTitleActive : classes.articleTitle}
          position="relative"
          bgcolor="white"
          border={2}
          borderColor="primary.main"
          p={2}
        >
          <Typography color="inherit" style={{fontSize: 25, fontWeight: 400, }} noWrap={articleActive ? false : true}>{article.title}</Typography>
          {articleActive && <Typography align="justify" style={{fontSize: 17, padding: 16}}>{article.description}</Typography>}
          {articleActive &&
            <Button
              variant="contained"
              color="secondary"
              style={{position:"absolute", bottom: 24, margin:"auto",width: "50%", marginTop: 24, borderRadius: 0, textTransform: "inherit", fontSize: 20, color: "white", fontWeight: 300}}
              onClick={!edit ? withModal ? ()=>setOpenModal(true) : ()=>downloadFile(article.title.replace(/ /g, "_")+".pdf", article.pdfUrl) : null}
            >
              Prenesi
            </Button>
          }
        </Box>
        <Box height={250} width="100%" overflow="hidden">
          <img alt="background" src={article.fileUrl} width="100%" height="auto"/>
        </Box>
        <Box height={150} position="relative" p={3} overflow="hidden">
          <Typography align="justify" className={classes.description}>{article.description}</Typography>
        </Box>
        <Box height={40} position="relative" bgcolor="primary.main" color="white" px={3} display="flex" flexDirection="column" justifyContent="center">
          <Typography color="inherit" align="justify" style={{fontSize: 17}}>5 maj 2019</Typography>
        </Box>
      </Box>
    </React.Fragment>

  )
}

function WaterArticles(props) {
  const { classes, articles, style } = props;


  return (
    <Grid
      container
      justify="space-around"
      spacing={9}
      style={style ?  style.reduce((obj, tmp)=> {return {...obj, [tmp.name]: tmp.value}},{}) : null}
      className={classes.root}
    >
        {articles && articles.map((article, i)=>(
            <Grid
              key={i}
              xs={12}
              sm={6}
              md={4}
              lg={4}
              xl={3}
              item
            >
              <Article article={article} {...props}/>
            </Grid>
        ))}
    </Grid>
  );
}

WaterArticles.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = () => {
  return {}
}

export default connect(mapStateToProps, actions)(editWrapper(withStyles(styles)(WaterArticles)))
