import React from 'react';
import {connect} from "react-redux";
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import Grow from '@material-ui/core/Grow';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import SettingsIcon from '@material-ui/icons/Settings';
import ConfirmIcon from '@material-ui/icons/Done';
import CancelIcon from '@material-ui/icons/Clear';
import CopyIcon from '@material-ui/icons/FileCopy';
import MoveIcon from '@material-ui/icons/ZoomOutMap';
import EditIcon from '@material-ui/icons/Edit';
import LeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import RightIcon from '@material-ui/icons/KeyboardArrowRight';
import BoxIcon from '@material-ui/icons/Unarchive';
import PersonIcon from '@material-ui/icons/Person';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import * as actions from './../../../redux/actions'

function editWrapper(WrappedComponent){

  function EditWrapper(props){

    const {id, edit, selectedElement, elements, type, auth, selectedPage} = props

    const [menuActive, setMenuActive] = React.useState(false)
    const [menuMove, setMenuMove] = React.useState(false)
    const [menuDeleteConfirm, setMenuDeleteConfirm] = React.useState(false)

    const elementParent = elements[elements[selectedElement] && elements[selectedElement].parent]
    const basicMode = auth && auth.userInfo && auth.userInfo.privileges && auth.userInfo.privileges.basic


    const selectedElementStyle = {
      position: "relative",
      border: "1px solid rgba(144, 202, 249, .5)",
      width: "inherit",
      height: "inherit",
      background: "rgba(144, 202, 249,0.1)",
      padding: 8,
      boxShadow: "0px 0px 5px 0px rgba(144, 202, 249,0.75)",


    }

    const handleElementSelect = (e) => {
      e.stopPropagation()
      setMenuActive(false)
      props.setSelectedElement(id)
    }

    const handleParentSelect = (e)=>{

      e.stopPropagation()
      setMenuActive(false)
      props.setSelectedElement(elements[selectedElement].parent)
    }

    const handleMenu = (e)=>{
      e.stopPropagation()

      if(basicMode) props.setActiveModal("elementModal")
      else setMenuActive(true)

    }

    const handleEdit = (e)=>{
      e.stopPropagation()
      setMenuActive(false)
      props.setActiveModal("elementModal")
    }

    const handleDelete = (e)=>{
      e.stopPropagation()
      if(menuDeleteConfirm){
        setMenuActive(false)
        setMenuDeleteConfirm(false)
        props.deleteSelectedElement(selectedElement)
      }else{
        setMenuDeleteConfirm(true)
      }
    }

    const handleCancelDelete = (e)=>{
      e.stopPropagation()
      setMenuDeleteConfirm(false)
    }

    const handleAddElement = (e)=>{
      e.stopPropagation()
      setMenuActive(false)
      props.setActiveModal("elementAddModal")
    }

    const handleCopyElement = (e)=>{
      e.stopPropagation()
      setMenuActive(false)
      props.setActiveModal("copyElement")
    }

    const handleToggleMenuMove = (e)=>{
      e.stopPropagation()
      //if element parent has more than one child, toggle menu move
      if(elementParent.children.length > 1)setMenuMove(!menuMove)
      else props.setActiveModal("moveElement")
    }

    const handleMoveElement = (e)=>{
      e.stopPropagation()
      setMenuActive(false)
      props.setActiveModal("moveElement")
    }

    const handleSwitchElementPositionLeft = (e)=>{
      e.stopPropagation()
      props.switchElementPositionInsideParent(selectedPage, selectedElement,-1)
    }

    const handleSwitchElementPositionRight = (e)=>{
      e.stopPropagation()
      props.switchElementPositionInsideParent(selectedPage, selectedElement, 1)
    }

    const menu = (
        <Box position="absolute" top={-28} right={-28} display="flex" flexDirection="column" style={{zIndex: 1001}}>

          {menuActive ? (
            <Box position="relative"  >
              {
                menuDeleteConfirm ? (
                  <React.Fragment>
                    <Grow in={menuDeleteConfirm} timeout={1000}>
                      <Fab
                        size="medium"
                        color="secondary"
                        style={{background: green[300], position:"absolute", color: "white", top: -28, left: -50}}
                        onClick={handleDelete}>
                        <ConfirmIcon />
                      </Fab>
                    </Grow>
                    <Grow in={menuDeleteConfirm} timeout={2000}>
                      <Fab
                        size="medium"
                        color="secondary"
                        style={{background: red[300], position:"absolute", color: "white", top: 30, left: -50}}
                        onClick={handleCancelDelete}>
                        <CancelIcon />
                      </Fab>
                    </Grow>
                    <Fab color="primary" onClick={handleCancelDelete}><DeleteIcon /></Fab>
                  </React.Fragment>
                ):menuMove ? (
                  <React.Fragment>
                    <Grow in={menuMove} timeout={1500} mountOnEnter>
                      <Fab
                        size="medium"
                        color="secondary"
                        style={{position:"absolute", color: "white", top: -56, left: 4}}
                        onClick={handleMoveElement}>
                        <BoxIcon />
                      </Fab>
                    </Grow>
                    <Grow in={menuMove} timeout={1000} mountOnEnter>
                      <Fab
                        size="medium"
                        color="secondary"
                        style={{position:"absolute", color: "white", top: -28, left: -48}}
                        disabled={elementParent && elementParent.children[0] === id }
                        onClick={handleSwitchElementPositionLeft}>
                        <LeftIcon />
                    </Fab>
                    </Grow>
                    <Grow in={menuMove} timeout={500} mountOnEnter>
                      <Fab
                        size="medium"
                        color="secondary"
                        style={{position:"absolute", color: "white", top: -28, left: 56}}
                        onClick={handleSwitchElementPositionRight}
                        disabled={elementParent && elementParent.children[elementParent.children.length - 1] === id }
                      >
                        <RightIcon />
                      </Fab>
                    </Grow>
                    <Fab color="primary" onClick={handleToggleMenuMove}><MoveIcon /></Fab>
                  </React.Fragment>
                ):(
                  <React.Fragment>
                      <Grow in={true} timeout={2500}>
                        <Fab
                          size="medium"
                          color="secondary"
                          style={{background: red[300], position:"absolute", color: "white", top: -56, left: 4}}
                          onClick={handleDelete}>
                          <DeleteIcon />
                        </Fab>
                      </Grow>
                      <Grow in={true} timeout={2000}>
                        <Fab
                          size="medium"
                          color="secondary"
                          style={{position:"absolute", color: "white", top: -28, left: -48}}
                          onClick={handleAddElement}>
                          <AddIcon />
                      </Fab>
                      </Grow>
                      <Grow in={true} timeout={500}>
                        <Fab
                          size="medium"
                          color="secondary"
                          style={{position:"absolute", color: "white", top: 30, left: -50}}
                          onClick={handleCopyElement}><CopyIcon />
                        </Fab>
                      </Grow>
                      <Grow in={true} timeout={1000}>
                        <Fab
                          size="medium"
                          color="secondary"
                          style={{position:"absolute", color: "white", top: 64, left: 4}}
                          onClick={handleToggleMenuMove}>
                          <MoveIcon />
                        </Fab>
                      </Grow>
                      <Grow in={true} timeout={1500}>
                        <Fab
                          size="medium"
                          color="secondary"
                          style={{ position:"absolute", color: "white", top: 30, left: 58}}
                          onClick={handleParentSelect}>
                          <PersonIcon />
                        </Fab>
                      </Grow>


                    <Fab color="primary"  onClick={handleEdit}><EditIcon /></Fab>
                  </React.Fragment>
                )
              }
            </Box>
          ):(
            <Fab  color="primary" onClick={handleMenu}><SettingsIcon /></Fab>
          )}
        </Box>
    )

    const label = (
      <Box position="absolute" zIndex={1000} top={0} left={0} p={1}  bgcolor="secondary.main" color="white">
        <Typography color="inherit" style={{fontSize: 10}}>{props.name}</Typography>
      </Box>
    )

    if(edit){

      if(type === "grid_item" || type === "custom_element_tehnofan_circle_image_button"){

        return (
          <Grid
            item
            xs={props.xs}
            sm={props.sm}
            md={props.md}
            lg={props.lg}
            onClick={handleElementSelect}
            style={selectedElement === id ? selectedElementStyle : {}}
          >
            {selectedElement === id && menu}
            {selectedElement === id && label}
            <Grid container style={{width: "100%", height: "inherit"}}>
              <WrappedComponent
                {...props}
                xs={12}
                sm={12}
                md={12}
                lg={12}/>
             </Grid>
          </Grid>
        )
      }else{
        return (
          <Box
            onClick={handleElementSelect}
            style={selectedElement === id ? selectedElementStyle: {width: "inherit", height: "inherit"}}>
            {selectedElement === id && menu}
            {selectedElement === id && label}
            <WrappedComponent {...props}/>
          </Box>
        )
      }
    }else return <WrappedComponent {...props}/>
  }

  const mapStateToProps = ({elements, selectedElement, auth, selectedPage}) => {
    return {
      elements,
      selectedElement,
      selectedPage,
      auth
    }
  }

  return connect(mapStateToProps, actions)(EditWrapper)
}

export default editWrapper
