

export const styles = (theme) => {
  return {
    contentBackground:{
      background: "linear-gradient(to bottom, #FFFFFF 0%, rgba(255, 255, 255, 0) 30%)"
    },
    homePageTitle:{
      fontWeight: 200,
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      color: theme.palette.primary.text,

      [theme.breakpoints.down('xs')]: {
        fontSize: 35,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 45,
      },
      [theme.breakpoints.up('md')]: {
        fontSize: 45,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 50,
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: 60,
      },
    },

    carouselTitle:{
      fontWeight: 300,

      color: theme.palette.primary.text,

      [theme.breakpoints.down('xs')]: {
        fontSize: 35,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 45,
      },
      [theme.breakpoints.up('md')]: {
        fontSize: 45,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 50,
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: 60,
      },
    },

    carouselTitleSmall:{
      fontWeight: 200,

      color: theme.palette.primary.text,

      [theme.breakpoints.down('xs')]: {
        fontSize: 25,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 35,
      },
      [theme.breakpoints.up('md')]: {
        fontSize: 35,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 40,
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: 45,
      },
    },

    carouselTitleBold:{
      fontWeight: 300,

      color: "white",
      background: theme.palette.primary.main,

      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),

      [theme.breakpoints.down('xs')]: {
        fontSize: 30,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 35,
      },
      [theme.breakpoints.up('md')]: {
        fontSize: 35,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 40,
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: 40,
      },
    },

    carouseltButton:{

      textTransform: "initial",
      color: "white",

      padding: 8,

      fontWeight: 200,



      [theme.breakpoints.down('xs')]: {
        fontSize: 25,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 30,
      },
      [theme.breakpoints.up('md')]: {
        fontSize: 30,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 30,
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: 35,
      },
    },

    paragraphTitle:{
      fontWeight: 200,

      paddingBottom: theme.spacing.unit * 2,
      color: theme.palette.primary.text,

      [theme.breakpoints.down('xs')]: {
        fontSize: 35,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 45,
      },
      [theme.breakpoints.up('md')]: {
        fontSize: 45,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 50,
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: 60,
      },
    },
    paragraphTitleSmall:{
      fontWeight: 300,

      paddingBottom: theme.spacing.unit * 3,
      color: theme.palette.primary.text,

      [theme.breakpoints.down('xs')]: {
        fontSize: 15,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 20,
      },
      [theme.breakpoints.up('md')]: {
        fontSize: 25,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 30,
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: 35,
      },
    },
    paragraphTextItem:{

      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",

    },
    paragraphText:{
      paddingBottom: theme.spacing.unit * 3,

      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      aligItems: "center"
    },
    paragraphImage:{

      width: "100%",
      height: "auto",
      boxShadow: theme.shadows[3],
    },
    dropdownButton:{

      color: "white",
      fontWeight: 300,
      minWidth: 150,
      padding: theme.spacing.unit * 1,
      textTransform: "initial",

      '&:hover':{
        background: theme.palette.secondary.main
      },

      [theme.breakpoints.down('sm')]:{
        width: "100%",
        background: theme.palette.secondary.main,
        fontSize: "1rem",
      },
      [theme.breakpoints.up('md')]: {
        fontSize: "1rem",
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: "1rem",
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: "1.1rem",
      },
    },
    dropdownButtonHighlighted:{

      color: "white",
      fontWeight: 300,
      minWidth: 150,
      padding: theme.spacing.unit * 1,
      textTransform: "initial",
      background: theme.palette.secondary.main,

      '&:hover':{
        background: "rgba(0,0,0,0.3)"
      },

      [theme.breakpoints.up('md')]: {
        fontSize: "1rem",
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: "1rem",
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: "1.1rem",
      },
    },
    navButton:{

      height: "100%",
      color: "white",
      fontWeight: 300,
      borderRadius: 0,
      textTransform: "initial",


      paddingLeft: theme.spacing.unit * 1,
      paddingRight: theme.spacing.unit * 1,


      [theme.breakpoints.up('md')]: {
        fontSize: "0.85rem",
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: "1rem",
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: "1.1rem",
      },

      '&:hover':{
        background: theme.palette.secondary.main,

      }
    },
    shopButton:{

      height: "100%",
      color: "white",
      fontWeight: 300,
      borderRadius: 0,
      textTransform: "initial",

      background: "rgba(0, 173, 239, 0.8)",
      paddingLeft: theme.spacing.unit * 1,
      paddingRight: theme.spacing.unit * 1,


      [theme.breakpoints.up('md')]: {
        fontSize: "0.85rem",
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: "1rem",
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: "1.1rem",
      },

      '&:hover':{
        background: theme.palette.secondary.main,

      }
    },
    contentButton:{
      borderRadius: 0,
      textTransform: "initial",
      color: "white",

      padding: 8,

      fontWeight: 200,


      [theme.breakpoints.down('md')]: {
        fontSize: 20,
      },
      [theme.breakpoints.up('md')]: {
        fontSize: 20,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 25,
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: 25,
      },
    },
    bottomContentButton:{
      borderRadius: 0,
      textTransform: "initial",
      color: "white",
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      padding: 8,

      fontWeight: 200,

      flexGrow: 1,
      [theme.breakpoints.down('md')]: {
        fontSize: 20,
      },
      [theme.breakpoints.up('md')]: {
        fontSize: 20,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 25,
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: 25,
      },
    },
    imageLogo:{
      width: "auto",
      margin: 24,
      cursor: "pointer",
      height: 50,


    },
    shadow:{
      boxShadow: theme.shadows[3]
    }

  }
}


 export const allClasses = [
   "paragraphTitleSmall",

   "homePageTitle",
   "carouselTitleSmall",
   "carouselTitleBold",
   "carouselTitle",
   "carouseltButton",
   "paragraphTitle",
   "paragraphImage",
   "navButton",
   "shopButton",
   "dropdownButton",
   "dropdownButtonHighlighted",
   "contentButton",
   "bottomContentButton",
   "paragraphText",
   "paragraphTextItem",
   "contentBackground",
   "imageLogo"
 ]
