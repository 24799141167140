import {
  FETCH_DICTIONARY,
  FETCH_DICTIONARY_LANGUAGES,
  FETCH_DICTIONARY_LANGUGAE_DATA,
  UPDATE_ELEMENT_LANGUAGE,
  ADD_DICTIONARY_LANGUAGE,
  DELETE_DICTIONARY_LANGUAGE
} from "./../../../actions/types";

export default (state = "loading", action) => {
  let dictionary = state !== "loading" ? {...state} : {}

  switch (action.type) {
    case FETCH_DICTIONARY:
      return action.payload;
    case FETCH_DICTIONARY_LANGUAGES:
      dictionary.languages = action.payload
      return dictionary;
    case FETCH_DICTIONARY_LANGUGAE_DATA:
      if(dictionary.data)dictionary.data[action.languageId] = action.payload
      else dictionary = {...dictionary, ...{data:{[action.languageId]: action.payload}}}
      return dictionary;
    case ADD_DICTIONARY_LANGUAGE:
      dictionary.languages[action.id] = {label: action.label}
      return dictionary
    case DELETE_DICTIONARY_LANGUAGE:
      delete dictionary.languages[action.id]
      return dictionary
    case UPDATE_ELEMENT_LANGUAGE:
        const {elementId, pageId, payload, language} = action

        if(dictionary.data[language].pages[pageId]){
          dictionary.data[language].pages[pageId] = {
            elements:{
              [elementId]: payload
            }
          }
          return dictionary
        }else{
          dictionary.data[language].pages[pageId].elements[elementId] = payload
          return dictionary
        }
    default:
      return state;
  }
};
