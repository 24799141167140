
import { SET_ACTIVE_MODAL, SET_PREVIOUS_MODAL, CLOSE_ACTIVE_MODAL } from "./../../actions/types";


export default (state = {active: "", history:[]}, action) => {
  let currState = {...state}

  switch (action.type) {
    case SET_ACTIVE_MODAL:

      //do nothing if modal is already active
      if(currState.active !== action.payload){
        currState.history.push(currState.active);
        currState.active = action.payload
      }

      return currState
    case SET_PREVIOUS_MODAL:

      //activate previously active modal and remove it from array history
      if(currState.active !== ""){
        let prevousActiveModal = currState.history.pop();
        currState.active = prevousActiveModal
        return currState
      }else return currState
    case CLOSE_ACTIVE_MODAL:
      //closes active modal and delete all history
      return {active: "", history:[]}
    default:
      return currState;
  }
};
